import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Button,
    MenuItem,
    Modal,
    Autocomplete,
  } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { tokens } from "../theme";

import axios from 'axios';
import { global_link } from '../Components/Globallink';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';
import CancelIcon from '@mui/icons-material/Cancel';
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';


// SUBMIT NOTIFICATIONS
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
// SUBMIT NOTIFICATIONS


const api = axios.create({baseURL: global_link});


function Sms() {

const theme = useTheme();
const colors = tokens(theme.palette.mode);
const [selectedTab, setSelectedTab] = useState(0);
const [Endpoint, setEndpoint] = useState('');
const [APIKey, setAPIKey] = useState('');
const [shortCode, setShortCode] = useState('');
const [partnerID, setPartnerID] = useState('');
const [selectedPhoneOptions, setSelectedPhoneOptions] = useState([]);
const [templateOpen, setTemplateOpen] = useState(false);

const [formData, setFormData] = useState({});
const [headerResults, setHeaderResults] = useState([]);
const [tempValue, setTempValue] = useState('');
const [phoneval, setPhoneValue] = useState('');


const [options, setOptions] = useState([]); // Store the options fetched from the API
const [searchTerm, setSearchTerm] = useState(''); // Track the user's search term
const [selectedOption, setSelectedOption] = useState('');
const [searchText, setSearchText] = useState('');
const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState('');
const [isSMSOpen, setSMSOpen] = useState(false);

// SUBMIT NOTIFICATIONS

const [openSuccess, setOpenSuccess] = useState(false);
const [openError, setOpenError] = useState(false);

const handleSuccessClose = (event, reason) => {
  if (reason === 'clickaway') {return;}setOpenSuccess(false);}; 

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {return;}setOpenError(false);}; 


  const [state, setState] = useState({openServer: false,vertical: 'top',horizontal: 'center',});
  const { vertical, horizontal} = state;

  const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
    

  useEffect(() => {
    setOpenSuccess(false);
    setOpenError(false);
  },[])

// SUBMIT NOTIFICATIONS




useEffect(() => {
  // Fetch data from the API when the component mounts
  fetchPhoneData();
}, []);


const fetchPhoneData = async () => {
  try {
    const response = await api.get('/contacts/'); // Replace with your API endpoint
    setOptions(response.data.phone_number); // Update the options state with the fetched data
  } catch (error) {
    console.log('Error fetching options:', error);
  }
};


const handleOptionSelect = async (event, value) => {
  setSelectedOption(value); // Update the selected option when an option is selected
  setSearchTerm('');
  
  try {
  const accessToken = localStorage.getItem('access_token');
  const headers = {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',};
    const response = await axios.get(`/searchcontact/`,{ params: {
      search: value,
      page: 1,
      size: 10,
    },
      headers,
      withCredentials: true,
    }); // Replace with your API endpoint
    // Process the response data as needed
    console.log(response.data);
    alert(response.data)
  } catch (error) {
    console.log('Error fetching data:', error);
  }
};


const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
 const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const modalStyle =  {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
  };



  const smsInfo = () => {
    const accessToken = localStorage.getItem('access_token');
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',};
    api.get('/smssettingsclient/', {
        headers,
        withCredentials: true,
      }).then(response => {
          setEndpoint(response.data.end_point);
          setAPIKey(response.data.api_key);
          setShortCode(response.data.short_code);
          setPartnerID(response.data.partner_id);})
        .catch(error => {
          console.error(error);
        });}

  
        const headerInfo = () => {
          const accessToken = localStorage.getItem("access_token");
          const headers = {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          api.get('/allsmstemplatecontent/', {
            headers,
            withCredentials: true,
          })
            .then(response => {
              const resultdata = response.data;
              const filteredResults = resultdata.filter(item =>
                item.header);
              setHeaderResults(filteredResults);
            })
            .catch(error => {
              console.error(error);
            }); }
  

        useEffect(() => {
          smsInfo();
          headerInfo();
        },[]);




  const  handleTemplateSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    api.post('/addsmstemplate/', formData,{ withCredentials: true})
      .then((response) => {
        fetchData();
        setTemplateOpen(false);
        
      })
      .catch((error) => {
        console.error(error);
      });
        }

  const  handleTemplateDelete = (id) => { 
    api.delete(`/deletetemplate/${id}`, { withCredentials: true })
    .then((response) => {
      fetchData();
      setEditTemplateOpen(false);
    })
    .catch((error) => {
      setEditTemplateOpen(true);
    });
    
  }

  const  handleTemplateEditSubmit = (event) => { 
    event.preventDefault();
    const formData = new FormData(event.target);
    const id = formData.get('temp_id');
    api.put(`/updatetemplate/${id}`, formData,{ withCredentials: true})
      .then((response) => {
        fetchData();
        setEditTemplateOpen(false);
      })
      .catch((error) => {
        console.error(error);
        setEditTemplateOpen(true);
      });

    
  }

//   const  handleSMSSubmit = (event) => {
//     event.preventDefault();
//     const formData = new FormData(event.target);
//       if(formData.get('mobile2')){
//         const params = {mobile : selectedPhoneNumbers, message: formData.get('message') }
//         api.post('/sendsms/', params,{withCredentials: true,})
//         .then((response) => {setOpenSuccess(true);document.getElementById('contact-form').reset(); setTempValue(""); setSelectedPhoneNumbers("");setSelectedContacts([]);setTempValue([]);
//         fetchSMSData();
//       })
//         .catch((error) => {
//         setOpenError(true);
//           console.error(error);});
//       }else{
//         const params = {mobile : formData.get('mobile'), message: formData.get('message')}
//         api.post('/sendsms/', params,{withCredentials: true,})
//         .then((response) => {setOpenSuccess(true);document.getElementById('contact-form').reset(); setTempValue(""); setSelectedPhoneNumbers("");setSelectedContacts([]);setTempValue([]);
//         fetchSMSData();
//       })
//         .catch((error) => {
//         setOpenError(true);
//           console.error(error);})
//       }

// }




  const  handleSMSSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    if(selectedPhoneNumbers.length!=0){
        const params = {mobile : selectedPhoneNumbers, message: formData.get('message') }
        api.post('/sendsms/', params,{withCredentials: true,})
        .then((response) => {setOpenSuccess(true);document.getElementById('contact-form').reset(); setTempValue(""); setSelectedPhoneNumbers("");setSelectedContacts([]);setTempValue([]);
        fetchSMSData();
        })
          .catch((error) => {
          setOpenError(true);
            console.error(error);});
    }
    else{
      const params = {mobile : formData.get('mobile2'), message: formData.get('message')}
      api.post('/sendsms/', params,{withCredentials: true,})
      .then((response) => {setOpenSuccess(true);document.getElementById('contact-form').reset(); setTempValue(""); setSelectedPhoneNumbers("");setSelectedContacts([]);setTempValue([]);
      fetchSMSData();
      })
        .catch((error) => {
        setOpenError(true);
          console.error(error);});
    }

}

  const handleTempValue = (event) => {
    const { name, value } = event.target;
  }

  const [searchSMSText, setSearchSMSText] = useState('');
  const [filteredSMSData, setFilteredSMSData] = useState([]);
  const [totalSMSItems, setTotalSMSItems] = useState(0);
  const [smscontroller, setSMSController] = useState({
    smspage: 1,
    smsrowsPerPage: 10
  });
  const [editTemplateOpen, setEditTemplateOpen] = useState(false);
  

  const fetchSMSData = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await api.get('/allsms/',
        { params: {
          search: searchSMSText,
          page: smscontroller.smspage,
          size: smscontroller.smsrowsPerPage,
        },
          headers,
          withCredentials: true,
        }
      );
      const data = response.data;
      setFilteredSMSData(data.results);
      setTotalSMSItems(data.count);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSMSData();
  }, [searchSMSText, smscontroller]);


  const handleSMSRowClick = (item) => {
    setSelectedItem(item);
    setEditTemplateOpen(true);
  };

  const handleSMSChangePage = (event, newPage) => {
    setSMSController({
      ...smscontroller,
      smspage: newPage
    });
  };

  const handleSMSChangeRowsPerPage = (event) => {
    setSMSController({
      ...smscontroller,
      smsrowsPerPage: parseInt(event.target.value, 10),
      smspage: 1
    });
  };

  const handleInputModChange = (event) => {
    const { name, value } = event.target;
    setSelectedItem((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));
  };




  //SEARCH PHONE NUMBER AUTOCOMPLETE
const [selectedContacts, setSelectedContacts] = useState([]);
const [contactOptions, setContactOptions] = useState([]);
const [searchContact, setSearchContact] = useState('');

useEffect(() => {
  const accessToken = localStorage.getItem('access_token');
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };
  api.get('/phonecontacts/', {headers,withCredentials: true,}) // Replace with your actual API URL
    .then(response => {
      setContactOptions(response.data); // Assuming response.data is the array of objects
    })
    .catch(error => console.error('Error fetching contacts:', error));
}, []);

const handleContactChange = (event, value) => {
  setSelectedContacts(value);
  setSelectedPhoneNumbers(value.map(contact => contact.phone_number).join(', '));
};



  
  const [filteredData, setFilteredData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10
  });

  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchData = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await api.get('/smstemplates/',
        { params: {
          search: searchText,
          page: controller.page,
          size: controller.rowsPerPage,
        },
          headers,
          withCredentials: true,
        }
      );
      const data = response.data;
      setFilteredData(data.results);
      setTotalItems(data.count);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchText, controller]);


  const handleChangePage = (event, newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 1
    });
  };


  const handleHeaderChange = (event) => {
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
    const contentvalue = event.target.value;
    api.get(`smstemplatecontent/${contentvalue}`, {
      headers,
      withCredentials: true,
    }).then(response => {     
        const data = response.data;
        const template = data[0].template; 
        setTempValue(template);
      })
      .catch(error => {
        console.error(error);
      });
  
  };

  const handleRowClick = (item) => {
    setSelectedItem(item);
    setSMSOpen(true);
  };



  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3 }}>
      <TokenExpiration/>
      <Expiration/>
    <Typography variant="h6" paddingBottom={2}>SMS</Typography>
    <Grid  spacing={2}  p={1} >
        <Grid item xs={12} sx={{ backgroundColor: `${colors.primary[700]} !important`, paddingBottom: '10px',marginTop:'10px',borderRadius:'5px',padding:'10px !important'}}>
<Box position="sticky"
    top="-10px">
<Tabs
  value={selectedTab}
  onChange={handleTabChange}
  textColor="secondary"
  indicatorColor="secondary"
  variant="fullWidth"
      >
        <Tab label="Create" onClick={headerInfo}/>
        <Tab label="Outbox" onClick={fetchSMSData}/>
        {/* <Tab  label="Scheduled"/> */}
        <Tab  label="SMS Templates" onClick={fetchData}/>
      </Tabs>

      </Box>

      {selectedTab === 0 && <span>


     <form onSubmit={handleSMSSubmit} id="contact-form">
        <Grid container spacing={2} p={2}>



        <Grid item xs={12}>
        <TextField
  fullWidth
  select
  label="Template"
  name="template"
  sx={{ marginTop: "10px" }}
  onChange={handleHeaderChange}
>
  <MenuItem value="">Select Template</MenuItem>
  {headerResults.map((headerinfo, i) => (
    <MenuItem key={i} value={headerinfo.id}>{headerinfo.header}</MenuItem>
  ))}
</TextField>
</Grid>


<Grid item xs={6}>
<Autocomplete
      multiple
      id="contact-autocomplete"
      options={contactOptions}
      getOptionLabel={(option) => option.phone_number}
      value={selectedContacts}
      onChange={handleContactChange}
      renderInput={(params) => (
        <TextField
        {...params}
        fullWidth
        label="Selected Phone Numbers"
        name="mobile"
        onChange={setInputValue(selectedPhoneNumbers)}
        readOnly
        />
      )}
      filterOptions={(options, state) => {
        return options.filter((option) =>
          option.phone_number.toLowerCase().includes(state.inputValue.toLowerCase())
        );
      }}
    />
</Grid>

<Grid item xs={6}>
        <TextField
          label="External Phone Number"
          placeholder="External Phone Number"
          variant="outlined"
          fullWidth
          name="mobile2"
          value={phoneval}
          onChange={(e) => setPhoneValue(e.target.value)}
          type='text'
        />
        </Grid>

        <Grid item xs={12}>
        <TextField
          multiline
          rows={4}
          label="Textarea"
          placeholder="SMS"
          variant="outlined"
          fullWidth
          name="message"
          value={tempValue}
          required="true"
          onChange={(e) => setTempValue(e.target.value)}
        />
        </Grid>

        <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[500] }}

        >
         Send SMS
        </Button>
        </Grid>
        </Grid>
        </form>

            </span>}
            {selectedTab === 1 && <span>
                
              <Box p={2}>
      <TextField
        label="Search"
        variant="outlined"
        value={searchSMSText}
        onChange={(e) => setSearchSMSText(e.target.value)}
        style={{ marginBottom: '16px' }}
      />
      <TableContainer>
        <Table>
          <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
            <TableRow>
            <TableCell>#</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>More</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: colors.primary[700]}}>
            {filteredSMSData
              .map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{`${row.message.slice(0, 70)}...`}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell><Button key={row.id} variant='contained' sx={{backgroundColor: colors.blueAccent[600]}} onClick={() => handleRowClick(row)}><VisibilityIcon/></Button></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10,25,50,100]}
        component="div"
        count={totalSMSItems}
        rowsPerPage={smscontroller.smsrowsPerPage}
        page={smscontroller.smspage}
        onPageChange={handleSMSChangePage}
        onRowsPerPageChange={handleSMSChangeRowsPerPage}
        sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
      />
    </Box>

    <Modal
  open={isSMSOpen}
  onClose={() => setSMSOpen(false)}
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
>
  <Box backgroundColor={colors.primary[700]} sx={modalStyle}>
  <div className="modal-content">
      <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <h3 id="modal-title"> {selectedItem && (selectedItem.id)}</h3>
      <Box display="flex" justifyContent="right">
  <CancelIcon onClick={() => setSMSOpen(false)} sx={{cursor:"pointer"}}/>
  </Box>
      </Box>
      {selectedItem && (
    <Grid container spacing={1}>
<Grid item xs={4}>
      <TextField
        label="Type"
        name="type"
        fullWidth
        variant="outlined"
        value={selectedItem.type}
        readOnly
      />
</Grid>

<Grid item xs={4}>
      <TextField
        label="Status"
        name="status"
        fullWidth
        variant="outlined"
        value={selectedItem.status}
        readOnly
      />
</Grid>

<Grid item xs={4}>
      <TextField
        label="Date"
        name="date"
        fullWidth
        variant="outlined"
        value={selectedItem.date}
        readOnly
      />
</Grid>

<Grid item xs={12}>
<TextField
          multiline
          rows={2}
          label="Numbers"
          placeholder="Numbers"
          variant="outlined"
          fullWidth
          sx={{marginTop:"10px"}}
          name="numbers"
          value={selectedItem.phone_number}
          required="true"
          readOnly
        />
</Grid>
<Grid item xs={12}>
<TextField
          multiline
          rows={3}
          label="Message"
          placeholder="Message"
          variant="outlined"
          fullWidth
          sx={{marginTop:"10px"}}
          name="message"
          value={selectedItem.message}
          required="true"
          readOnly
        />
</Grid>


      </Grid>)}
  </div>
  </Box>
</Modal>

            </span>}
            {selectedTab === 2 && <span>
                
<Box display="flex" justifyContent="right" p={2}>
<Button
    variant='contained'
    sx={{
      backgroundColor: colors.blueAccent[600],
      color: colors.grey[100],
      fontSize: "14px",
      fontWeight: "bold",
      padding: "10px 20px",
      mr: "15px;",
      display: sessionStorage.getItem("user_level")==='supervisor' || sessionStorage.getItem("user_level")==='admin' ? '' : 'none',
    }}
    onClick={() => setTemplateOpen(true)}
  >
    <UploadOutlinedIcon sx={{ mr: "10px" }} />
    Add Template
  </Button>
</Box>

<Box p={2}>
      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: '16px' }}
      />
      <TableContainer>
        <Table>
          <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
            <TableRow>
              <TableCell>Header</TableCell>
              <TableCell>Template</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: colors.primary[700]}}>
            {filteredData
              .map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{row.header}</TableCell>
                  <TableCell>{row.template}</TableCell>
                  <TableCell><Button key={row.id} variant='contained' sx={{backgroundColor: colors.blueAccent[600],color:'white',display: sessionStorage.getItem("user_level")==='supervisor' || sessionStorage.getItem("user_level")==='admin' ? '' : 'none',}} onClick={() => handleSMSRowClick(row)}><DriveFileRenameOutlineIcon/></Button></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10,25,50,100]}
        component="div"
        count={totalItems}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
      />
    </Box>


    <Modal
        open={editTemplateOpen}
        onClose={() => setEditTemplateOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={modalStyle}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Edit SMS Template</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setEditTemplateOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          
            <form onSubmit={handleTemplateEditSubmit}>
            {selectedItem && (
        <Grid container spacing={2} p={2}>


      <Grid item xs={12}>

        <TextField
          label="Header"
          placeholder="Header"
          variant="outlined"
          fullWidth
          name="header"
          value={selectedItem.header || ""}
          onChange={handleInputModChange}
          required="true"
          type='text'
        />
        <TextField    
              type="hidden"
              name="temp_id"
              value={selectedItem.id}
              style={{ display: 'none' }}
              required="true"
            />
        </Grid>
        <Grid item xs={12}>
        <TextField
          multiline
          rows={4}
          label="Message"
          placeholder="SMS"
          variant="outlined"
          fullWidth
          name="template"
          required="true"
          value={selectedItem.template || ""}
          onChange={handleInputModChange}
        />
        </Grid>

        <Grid item xs={12} sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:colors.blueAccent[600]}}>Update</Button>
            <Button type="button" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:"#ED0800 !important",color:"white"}} onClick={() => handleTemplateDelete(selectedItem.id)}>Delete</Button>
            </Grid>
        </Grid>)}
        </form>

        </div>
        </Box>
      </Modal>






        <Modal
        open={templateOpen}
        onClose={() => setTemplateOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={modalStyle}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add SMS Template</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setTemplateOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          
            <form onSubmit={handleTemplateSubmit}>
        <Grid container spacing={2} p={2}>


      <Grid item xs={12}>

        <TextField
          label="Header"
          placeholder="Header"
          variant="outlined"
          fullWidth
          name="header"
          onChange={handleTempValue}
          required="true"
          type='text'
        />
        </Grid>
        <Grid item xs={12}>
        <TextField
          multiline
          rows={4}
          label="Message"
          placeholder="SMS"
          variant="outlined"
          fullWidth
          name="template"
          required="true"
          onChange={handleTempValue}
        />
        </Grid>

        <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[700] }}

        >
        Create Template
        </Button>
        </Grid>
        </Grid>
        </form>

        </div>
        </Box>
      </Modal>

                    </span>}

                    {selectedTab === 3 && <span></span>}
        </Grid>
        </Grid>

        <Stack spacing={2} sx={{ width: '100%'}}>
  <Snackbar open={openError} autoHideDuration={4000} onClose={handleErrorClose}  anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
  <Alert onClose={handleErrorClose} severity="error" sx={{ width: '100%' }}>
Error Occured !!!
  </Alert>
  </Snackbar>

  <Snackbar open={openSuccess} autoHideDuration={4000} onClose={handleSuccessClose}  anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
  <Alert onClose={handleSuccessClose} severity="success" sx={{ width: '100%' }}>
  Success 
  </Alert>
  </Snackbar>
  </Stack>

  </Box>
  );
}

export default Sms;