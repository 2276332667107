import React, { useEffect, useState, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ColorModeContext, tokens } from "../theme";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Button,
    MenuItem,
    Modal,
    Tabs, 
    Tab,
    Divider,
  } from '@mui/material';
import axios from 'axios';
import { global_link } from '../Components/Globallink';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';

const api = axios.create({baseURL: global_link});


function Ticketing() {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const [newticketopen, setNewTicketOpen] = useState(false);
  const [newticketeditopen, setNewTickeEdittOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [commentopen, setCommentOpen] = useState(false);
  const [commentlistopen, setCommentListOpen] = useState(false);
  const [selectedItemComment, setSelectedItemComment] = useState(null);
  const [comments, setComments] = useState([]);
  const [IndividualValue, setIndividualValue] = useState([]);
  const [UserID, setUserID] = useState([]);
  const [TickeCategoryOpen, setTickeCategoryOpen] = useState(false);
  const [TicketCatgoryData, setTicketCatgoryData] = useState([]);
  const [PropertyData, setPropertyData] = useState([]);
  const [stageModalOpen, setStageModalOpen] = useState(false);
  const [buyerModalOpen, setBuyerModalOpenOpen] = useState(false);
  const [propertyModalOpen, setPropertyModalOpen] = useState(false);

  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
  };



  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleNewCommentSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    api.post('/addcomment/', formData,{ withCredentials: true})
      .then((response) => {
        
        setCommentOpen(false);
        setNewTickeEdittOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  // TABLE 1
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10
  });
  useEffect(() => {
    fetchPropertyData();
  }, [searchText, controller]);
  const handleChangePage = (event, newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 1
    });
  };





  //// TABLE 2 ////
  const [searchBuyerText, setSearchBuyerText] = useState('');
  const [filteredBuyerData, setFilteredBuyerData] = useState([]);
  const [totalBuyerItems, setTotalBuyerItems] = useState(0);

  const [controllerBuyer, setBuyerController] = useState({
    page: 1,
    rowsPerPage: 10
  });

  useEffect(() => {
    fetchBuyerData();
  }, [searchBuyerText,controllerBuyer]);

  const handleChangeBuyerPage = (event, newPage) => {
    setBuyerController({
      ...controllerBuyer,
      page: newPage
    });
  };

  const handleChangeRowsBuyerPerPage = (event) => {
    setController({
      ...controllerBuyer,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 1
    });
  };

//// TABLE 2 ////

 //// TABLE 3 ////
  const [searchStageText, setSearchStageText] = useState('');
  const [filteredStageData, setFilteredStageData] = useState([]);
  const [totalStageItems, setTotalStageItems] = useState(0);

  const [controllerStage, setStageController] = useState({
    page: 1,
    rowsPerPage: 10
  });

  useEffect(() => {
    fetchStageData();
  }, [searchStageText,controllerStage]);

  const handleChangeStagePage = (event, newPage) => {
    setStageController({
      ...controllerStage,
      page: newPage
    });
  };

  const handleChangeRowsStagePerPage = (event) => {
    setController({
      ...controllerStage,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 1
    });
  };

//// TABLE 3 ////

 //// TABLE 4 ////
 const [searchLeadText, setSearchLeadText] = useState('');
 const [filteredLeadData, setFilteredLeadData] = useState([]);
 const [totalLeadItems, setTotalLeadItems] = useState(0);
 const [controllerLead, setLeadController] = useState({
   page: 1,
   rowsPerPage: 10
 });

 useEffect(() => {
   fetchLeadsData();
 }, [searchLeadText,controllerLead]);

 const handleChangeLeadPage = (event, newPage) => {
   setLeadController({
     ...controllerLead,
     page: newPage
   });
 };

 const handleChangeRowsLeadPerPage = (event) => {
   setController({
     ...controllerLead,
     rowsPerPage: parseInt(event.target.value, 10),
     page: 1
   });
 };

//// TABLE 4 ////













  const handleRowClick = (item) => {
    setSelectedItem(item);
    setNewTickeEdittOpen(true);
  };

  const handleInputModChange = (event) => {
    const { name, value } = event.target;
    setSelectedItem((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));
  };


  const handleAddCommentClick = (tckid) => {
    setCommentOpen(true);
    setSelectedItemComment(tckid);
  };

  const handleCommentListClick = (tckid) => {
    setCommentListOpen(true);
    fetchComments(tckid);
    

  };
  

  const fetchComments = (value) => {
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
      api.get(`/getcomments/${value}`,
      {
        headers,
        withCredentials: true,
      }).then(response => {
        setComments(response.data);
      })
      .catch(error => {
        console.error(error);
      })
  };


  const priorityColors = (priority) => {
    switch (priority) {
      case 'High':
        return 'red';
      case 'Medium':
        return 'orange';
      case 'Low':
        return 'darkorange';
      case 'Normal':
        return 'blue';
      case 'Urgent':
        return 'red';
      case 'Escalated':
        return 'purple';
      default:
        return 'inherit';
    }
  };

  const statusColors = (status) => {
    switch (status) {
      case 'Open':
        return 'red';
      case 'Assigned':
        return 'orange';
      case 'In Progress':
        return 'darkorange';
      case 'Pending':
        return 'blue';
      case 'On Hold':
        return 'red';
      case 'Resolved':
        return 'green';
      case 'Closed':
          return 'darkgreen';
      case 'Reopened':
          return 'darkred';
      case 'Escalated':
          return 'lightred';
      case 'Awaiting Customer Feedback':
          return 'purple';
      default:
        return 'inherit';
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
const headers = {
  'Authorization': `Bearer ${accessToken}`,
  'Content-Type': 'application/json',
};
    const indivvalue = "";
    const params = {search : indivvalue}
    api.post(`/getusers/`,params,{
      headers,
      withCredentials: true,
    })
      .then(response => {
        const indivresultdata = response.data;
        const filteredIndResults = indivresultdata.filter(item =>
          item.user_name.toLowerCase().includes(indivvalue.toLowerCase()) || 
          item.user_id.toLowerCase().includes(indivvalue.toLowerCase()) || 
          item.user_extension.toLowerCase().include(indivvalue.toLowerCase())
        );
        setIndividualValue(filteredIndResults);
      })
      .catch(error => {
        console.error(error);
      });

      api.get('/user/', {
        headers,
        withCredentials: true,
      })
        .then(response => {
    setUserID(response.data.user_id)
        });
    }, []);

      const [searchTicketText, setSearchEmailText] = useState('');
      const [filteredTicketData, setFilteredAssignedData] = useState([]);
      const [totalTicketItems, setTotalAssignedItems] = useState(0);
      const [ticketcontroller, setTicketController] = useState({
        ticketpage: 1,
        ticketrowsPerPage: 10
      });
      
    
      const fetchTicketAssignedData = async () => {
        try {
          const accessToken = localStorage.getItem("access_token");
          const headers = {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          const response = await api.get('/assignedticket/',
            { params: {
              search: searchTicketText,
              page: ticketcontroller.ticketpage,
              size: ticketcontroller.ticketrowsPerPage,
            },
              headers,
              withCredentials: true,
            }
          );
          const data = response.data;
          setFilteredAssignedData(data.results);
          setTotalAssignedItems(data.count);
        } catch (error) {
          console.error(error);
        }
      };
    
      useEffect(() => {
        fetchTicketAssignedData();
      }, [searchTicketText, ticketcontroller]);
    
    
      const handleTicketRowClick = (item) => {
        setSelectedItem(item);
      };
    
      const handleTicketChangePage = (event, newPage) => {
        setTicketController({
          ...ticketcontroller,
          ticketpage: newPage
        });
      };
    
      const handleTicketChangeRowsPerPage = (event) => {
        setTicketController({
          ...ticketcontroller,
          ticketrowsPerPage: parseInt(event.target.value, 10),
          ticketpage: 1
        });
      };
    
        const fetchPropertyData = async () => {
            try {
              const accessToken = localStorage.getItem("access_token");
              const headers = {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              };
              const response = await api.get('/getproperty/',
                { params: {
                  search: searchText,
                  page: controller.page,
                  size: controller.rowsPerPage,
                },
                  headers,
                  withCredentials: true,
                }
              );
              const data = response.data;
              setFilteredData(data.results);
              setTotalItems(data.count);
            } catch (error) {
              console.error(error);
            }
          };

          const fetchStageData = async () => {
            try {
              const accessToken = localStorage.getItem("access_token");
              const headers = {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              };
              const response = await api.get('/getstage/',
                { params: {
                  search: searchStageText,
                  page: controllerStage.page,
                  size: controllerStage.rowsPerPage,
                },
                  headers,
                  withCredentials: true,
                }
              );
              const data = response.data;
              setFilteredStageData(data.results);
              setTotalStageItems(data.count);
            } catch (error) {
              console.error(error);
            }
          };

          
          const fetchLeadsData = async () => {
            try {
              const accessToken = localStorage.getItem("access_token");
              const headers = {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              };
              const response = await api.get('/getleads/',
                { params: {
                  search: searchLeadText,
                  page: controllerLead.page,
                  size: controllerLead.rowsPerPage,
                },
                  headers,
                  withCredentials: true,
                }
              );
              const data = response.data;
              setFilteredLeadData(data.results);
              setTotalLeadItems(data.count);
            } catch (error) {
              console.error(error);
            }
          };


          

          const fetchBuyerData = async () => {
            try {
              const accessToken = localStorage.getItem("access_token");
              const headers = {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              };
              const response = await api.get('/getbuyers/',
                { params: {
                  search: searchBuyerText,
                  page: controllerBuyer.page,
                  size: controllerBuyer.rowsPerPage,
                },
                  headers,
                  withCredentials: true,
                }
              );
              const data = response.data;
              setFilteredBuyerData(data.results);
              setTotalBuyerItems(data.count);
            } catch (error) {
              console.error(error);
            }
          };

          const fetchMatchData = () => {
            const accessToken = localStorage.getItem("access_token");
            const headers = {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            };
            api.get('/ticketcategory/', {
              headers,
              withCredentials: true,
            })
              .then(response => {
                setTicketCatgoryData(response.data);
              })
              .catch(error => {
                console.error(error);
              });
    
          };
          const fetchFollowUpData = () => {
            const accessToken = localStorage.getItem("access_token");
            const headers = {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            };
            api.get('/ticketcategory/', {
              headers,
              withCredentials: true,
            })
              .then(response => {
                setTicketCatgoryData(response.data);
              })
              .catch(error => {
                console.error(error);
              });
          };
          const fetchVeiwingUpData = () => {
            const accessToken = localStorage.getItem("access_token");
            const headers = {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            };
            api.get('/ticketcategory/', {
              headers,
              withCredentials: true,
            })
              .then(response => {
                setTicketCatgoryData(response.data);
              })
              .catch(error => {
                console.error(error);
              });
          };

        useEffect(() => {
            fetchPropertyData();
        }, []);

      const handlePropertySubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        api.post('/addproperty/', formData,{ withCredentials: true})
          .then((response) => {
            fetchPropertyData();
            setPropertyModalOpen(false)
          })
          .catch((error) => {
            console.error(error);
          });
      };
      const handleBuyerSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        api.post('/addbuyer/', formData,{ withCredentials: true})
          .then((response) => {
            setBuyerModalOpenOpen(false);
            fetchBuyerData();
          })
          .catch((error) => {
            console.error(error);
          });
      };
      const handleStageSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        api.post('/addstage/', formData,{ withCredentials: true})
          .then((response) => {
            setStageModalOpen(false);
            fetchStageData();
          })
          .catch((error) => {
            console.error(error);
          });
      };


      const handleDeleteMatchSubmit = (id) => {
        api.delete(`/deletematch/${id}`,{ withCredentials: true})
          .then((response) => {
            fetchMatchData();
          })
          .catch((error) => {
            console.error(error);
          });
      };
      const handleDeleteBuyerSubmit = (id) => {
        api.delete(`/deletebuyer/${id}`,{ withCredentials: true})
          .then((response) => {
            fetchBuyerData();
          })
          .catch((error) => {
            console.error(error);
          });
      };
      const handleDeletePropertySubmit = (id) => {
        api.delete(`/deleteproperty/${id}`,{ withCredentials: true})
          .then((response) => {
            fetchPropertyData();
          })
          .catch((error) => {
            console.error(error);
          });
      };
      const handleDeleteStageSubmit = (id) => {
        api.delete(`/deletestagey/${id}`,{ withCredentials: true})
          .then((response) => {
            fetchStageData();
          })
          .catch((error) => {
            console.error(error);
          });
      };

  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3}}>
      <TokenExpiration/>
      <Expiration/>
    <Typography variant="h6" paddingBottom={2}>Ticketing ( THIS MODULE IS STILL UNDER DEVELOPMENT )</Typography>
    <div>
      <Tabs value={selectedTab} onChange={handleTabChange}   textColor="secondary"
  indicatorColor="secondary"
  variant="fullWidth"
  sx={{ backgroundColor: `${colors.primary[700]} !important`}}>
        <Tab label="Property" onClick={fetchPropertyData} />
        <Tab label="Buyers" onClick={fetchBuyerData} />
        <Tab label="Leads" onClick={fetchLeadsData} />
        <Tab label="Match" onClick={fetchMatchData} />
        <Tab label="Follow Up" onClick={fetchFollowUpData} />
        <Tab label="Veiwing" onClick={fetchVeiwingUpData} />
        {sessionStorage.getItem('user_level') === "supervisor" && (<Tab label="Stage" onClick={fetchStageData}  />)}
      </Tabs>
      <Divider/>

{selectedTab === 0 && ( 
    <Box sx={{marginTop:'20px',paddingLeft:'10px', flexGrow: 1,}}>
        <Box display='flex' justifyContent='right'>
        <Button variant='contained'
    sx={{
      backgroundColor: colors.blueAccent[600],
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
      mr: "15px;"
    }}
    onClick={() => setPropertyModalOpen(true)}>
      <AddIcon sx={{ mr: "5px" }} /> Add Property</Button>
      </Box>

 <TextField
 label="Search"
 variant="outlined"
 value={searchText}
 onChange={(e) => setSearchText(e.target.value)}
 style={{ marginBottom: '16px' }}
/>
<TableContainer>
 <Table>
   <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
     <TableRow>
     <TableCell>#</TableCell>
       <TableCell>Name</TableCell>
       <TableCell>Location</TableCell>
       <TableCell>Type</TableCell>
       <TableCell>Bedrooms</TableCell>
       <TableCell>Space</TableCell>
       <TableCell>Features</TableCell>
       <TableCell>Timeline</TableCell>
       {/* <TableCell>Status</TableCell> */}
       <TableCell>Date</TableCell>
       <TableCell>Action</TableCell>
     </TableRow>
   </TableHead>
   <TableBody sx={{backgroundColor: colors.primary[700]}}>
     {filteredData
       .map((row, index) => (
         <TableRow key={row.id}>
           <TableCell>{row.id}</TableCell>
           <TableCell>{row.property_name}</TableCell>
           <TableCell>{row.property_location}</TableCell>
           <TableCell>{row.property_type}</TableCell>
           <TableCell>{row.property_bedrooms}</TableCell>
           <TableCell>{row.property_space}</TableCell>
           <TableCell>{row.property_features}</TableCell>
           <TableCell>{row.property_timeline}</TableCell>
           {/* <TableCell>{row.property_status}</TableCell> */}
           <TableCell>{row.date ? row.date.slice(0, -16).replace('T', ' ') : (row.date)}</TableCell>
           <TableCell><Button key={row.id} variant='contained' sx={{backgroundColor: colors.blueAccent[600]}}><VisibilityIcon/></Button></TableCell>
         </TableRow>
       ))}
   </TableBody>
 </Table>
</TableContainer>
<TablePagination
 rowsPerPageOptions={[10,25,50,100]}
 component="div"
 count={totalItems}
 rowsPerPage={controller.rowsPerPage}
 page={controller.page}
 onPageChange={handleChangePage}
 onRowsPerPageChange={handleChangeRowsPerPage}
 sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
/>

<Modal
open={propertyModalOpen}
onClose={() => setPropertyModalOpen(false)}
aria-labelledby="modal-title"
aria-describedby="modal-description">
<Box backgroundColor={colors.primary[700]} sx={style}>
<div className="modal-content">
    <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    <h3 id="modal-title">Add Property</h3>
    <Box display="flex" justifyContent="right">
<CancelIcon onClick={() => setPropertyModalOpen(false)} sx={{cursor:"pointer"}}/>
</Box>
    </Box>
<form onSubmit={handlePropertySubmit}>
<Grid container spacing={1}>
<Grid item xs={6}>
    <TextField
    fullWidth    
        type="text"
        label="Name"
        placeholder="Property Name"
        name="property_name"
        onChange={handleInputChange}
        required="true"
    />
</Grid>
<Grid item xs={6}>
    <TextField
    fullWidth    
        type="text"
        label="Location"
        placeholder="Property Location"
        name="property_location"
        onChange={handleInputChange}
        required="true"
    />
</Grid>
<Grid item xs={6}>
    <TextField
    fullWidth    
        type="text"
        label="Type"
        placeholder=" Property Type"
        name="property_type"
        onChange={handleInputChange}
        required="true"
    />
</Grid>
<Grid item xs={6}>
    <TextField
    fullWidth    
        type="text"
        label="Bedrooms"
        placeholder=" Property Bedrooms"
        name="property_bedrooms"
        onChange={handleInputChange}
        required="true"
    />
</Grid>
<Grid item xs={6}>
    <TextField
    fullWidth    
        type="text"
        label="Space in Square Feet"
        placeholder=" Property Space"
        name="property_space"
        onChange={handleInputChange}
        required="true"
    />
</Grid>
<Grid item xs={6}>
    <TextField
    fullWidth    
        type="text"
        label="Timeline"
        placeholder=" Property Timeline"
        name="property_timeline"
        onChange={handleInputChange}
        required="true"
    />
</Grid>
<Grid item xs={12}>
    <TextField
    multiline
    rows={3}
    fullWidth    
        type="text"
        label="Features"
        placeholder=" Property Features"
        name="property_features"
        onChange={handleInputChange}
        required="true"
    />
</Grid>

</Grid> 
    <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white',backgroundColor: colors.blueAccent[600]}}>Add Property</Button>
    </form>
</div>
</Box>
</Modal>

</Box>
)}

{selectedTab === 1 && ( 
    <Box sx={{marginTop:'20px',paddingLeft:'10px', flexGrow: 1,}}>
                <Box display='flex' justifyContent='right'>
        <Button variant='contained'
    sx={{
      backgroundColor: colors.blueAccent[600],
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
      mr: "15px;"
    }}
    onClick={() => setBuyerModalOpenOpen(true)}>
      <AddIcon sx={{ mr: "5px" }} /> Add Buyer</Button>
      </Box>
    <TextField
    label="Search"
    variant="outlined"
    value={searchBuyerText}
    onChange={(e) => setSearchBuyerText(e.target.value)}
    style={{ marginBottom: '16px' }}
   />
   <TableContainer>
    <Table>
      <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
        <TableRow>
        <TableCell>#</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Phone Number</TableCell>
          <TableCell>Age</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Location</TableCell>
          <TableCell>Interest</TableCell>
          <TableCell>Comments</TableCell>
          <TableCell>Stage</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{backgroundColor: colors.primary[700]}}>
        {filteredBuyerData
          .map((row, index) => (
            <TableRow key={row.id}>
            <TableCell>{row.id}</TableCell>
           <TableCell>{row.name}</TableCell>
           <TableCell>{row.phone_number}</TableCell>
           <TableCell>{row.age}</TableCell>
           <TableCell>{row.email}</TableCell>
           <TableCell>{row.location}</TableCell>
           <TableCell>{row.interest}</TableCell>
           <TableCell>{row.comments}</TableCell>
           <TableCell>{row.stage}</TableCell>
           <TableCell>{row.status}</TableCell>
           <TableCell>{row.date ? row.date.slice(0, -16).replace('T', ' ') : (row.date)}</TableCell>
           <TableCell><Button key={row.id} variant='contained' sx={{backgroundColor: colors.blueAccent[600]}}><VisibilityIcon/></Button></TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
   </TableContainer>
   <TablePagination
    rowsPerPageOptions={[10,25,50,100]}
    component="div"
    count={totalBuyerItems}
    rowsPerPage={controllerBuyer.rowsPerPage}
    page={controllerBuyer.page}
    onPageChange={handleChangeBuyerPage}
    onRowsPerPageChange={handleChangeRowsBuyerPerPage}
    sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
   />

<Modal
open={buyerModalOpen}
onClose={() => setBuyerModalOpenOpen(false)}
aria-labelledby="modal-title"
aria-describedby="modal-description">
<Box backgroundColor={colors.primary[700]} sx={style}>
<div className="modal-content">
    <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    <h3 id="modal-title">Add Buyer</h3>
    <Box display="flex" justifyContent="right">
<CancelIcon onClick={() => setBuyerModalOpenOpen(false)} sx={{cursor:"pointer"}}/>
</Box>
    </Box>
<form onSubmit={handleBuyerSubmit}>
<Grid container spacing={1}>
<Grid item xs={6}>
    <TextField
    fullWidth    
        type="text"
        label="Name"
        placeholder="Name"
        name="name"
        onChange={handleInputChange}
        required="true"
    />
</Grid>
<Grid item xs={6}>
    <TextField
    fullWidth    
        type="text"
        label="Phone Number"
        placeholder="Phone Number"
        name="phone_number"
        onChange={handleInputChange}
        required="true"
    />
</Grid>
<Grid item xs={6}>
    <TextField
    fullWidth    
        type="email"
        label="Email (Optional)"
        placeholder="Email"
        name="email"
        onChange={handleInputChange}
        required="true"
    />
</Grid>
<Grid item xs={6}>
    <TextField
    fullWidth    
        type="text"
        label="Age"
        placeholder="Age"
        name="age"
        onChange={handleInputChange}
        required="true"
    />
</Grid>
<Grid item xs={6}>
    <TextField
    fullWidth    
        type="text"
        label="Location (County)"
        placeholder="Location (County)"
        name="location"
        onChange={handleInputChange}
        required="true"
    />
</Grid>
<Grid item xs={6}>
    <TextField
    fullWidth    
        type="text"
        label="Interest"
        placeholder="Interest"
        name="interest"
        onChange={handleInputChange}
        required="true"
    />
</Grid>
<Grid item xs={12}>
<TextField
        fullWidth
        select
        label="Status"
        name="status"
        onChange={handleInputChange}
        required="true"
      >
        <MenuItem value="">Status</MenuItem>
        <MenuItem value="Interested">Interested</MenuItem>
        <MenuItem value="Not Interested">Not Interested</MenuItem>
      </TextField>
</Grid>
<Grid item xs={12}>
    <TextField
    fullWidth    
    multiline
    rows={3}
        type="text"
        label="Comments"
        placeholder="Comments"
        name="comments"
        onChange={handleInputChange}
        required="true"
    />
</Grid>
</Grid> 
    <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white',backgroundColor: colors.blueAccent[600]}}>Add Buyer</Button>
    </form>
</div>
</Box>
</Modal>
   </Box>
)}

{selectedTab === 2 && ( 
    <Box sx={{marginTop:'20px',paddingLeft:'10px', flexGrow: 1,}}>
    <TextField
    label="Search"
    variant="outlined"
    value={searchText}
    onChange={(e) => setSearchText(e.target.value)}
    style={{ marginBottom: '16px' }}
   />
   <TableContainer>
    <Table>
      <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
        <TableRow>
        <TableCell>#</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Phone Number</TableCell>
          <TableCell>Age</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Location</TableCell>
          <TableCell>Interest</TableCell>
          <TableCell>Comments</TableCell>
          <TableCell>Stage</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{backgroundColor: colors.primary[700]}}>
        {filteredLeadData
          .map((row, index) => (
            <TableRow key={row.id}>
            <TableCell>{row.id}</TableCell>
           <TableCell>{row.name}</TableCell>
           <TableCell>{row.phone_number}</TableCell>
           <TableCell>{row.age}</TableCell>
           <TableCell>{row.email}</TableCell>
           <TableCell>{row.location}</TableCell>
           <TableCell>{row.interest}</TableCell>
           <TableCell>{row.comments}</TableCell>
           <TableCell>{row.stage}</TableCell>
           <TableCell>{row.status}</TableCell>
           <TableCell>{row.date ? row.date.slice(0, -16).replace('T', ' ') : (row.date)}</TableCell>
           <TableCell><Button key={row.id} variant='contained' sx={{backgroundColor: colors.blueAccent[600]}}><VisibilityIcon/></Button></TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
   </TableContainer>
   <TablePagination
    rowsPerPageOptions={[10,25,50,100]}
    component="div"
    count={totalLeadItems}
    rowsPerPage={controllerLead.rowsPerPage}
    page={controllerLead.page}
    onPageChange={handleChangeLeadPage}
    onRowsPerPageChange={handleChangeRowsLeadPerPage}
    sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
   />
   </Box>
)}

{selectedTab === 3 && ( 
    <Box sx={{marginTop:'20px',paddingLeft:'10px', flexGrow: 1,}}>
    <TextField
    label="Search"
    variant="outlined"
    value={searchText}
    onChange={(e) => setSearchText(e.target.value)}
    style={{ marginBottom: '16px' }}
   />
   <TableContainer>
    <Table>
      <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
        <TableRow>
        <TableCell>#</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Property</TableCell>
          <TableCell>Stage</TableCell>
          <TableCell>Phone Number</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{backgroundColor: colors.primary[700]}}>
        {filteredData
          .map((row, index) => (
            <TableRow key={row.id}>
            </TableRow>
          ))}
      </TableBody>
    </Table>
   </TableContainer>
   <TablePagination
    rowsPerPageOptions={[10,25,50,100]}
    component="div"
    count={totalItems}
    rowsPerPage={controller.rowsPerPage}
    page={controller.page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
   />
   </Box>
)}
{selectedTab === 4 && ( 
    <Box sx={{marginTop:'20px',paddingLeft:'10px', flexGrow: 1,}}>
    <TextField
    label="Search"
    variant="outlined"
    value={searchText}
    onChange={(e) => setSearchText(e.target.value)}
    style={{ marginBottom: '16px' }}
   />
   <TableContainer>
    <Table>
      <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
        <TableRow>
        <TableCell>#</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Phone Number</TableCell>
          <TableCell>Property</TableCell>
          <TableCell>Stage</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Comment</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{backgroundColor: colors.primary[700]}}>
        {filteredData
          .map((row, index) => (
            <TableRow key={row.id}>
            </TableRow>
          ))}
      </TableBody>
    </Table>
   </TableContainer>
   <TablePagination
    rowsPerPageOptions={[10,25,50,100]}
    component="div"
    count={totalItems}
    rowsPerPage={controller.rowsPerPage}
    page={controller.page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
   />
   </Box>    
)}
{selectedTab === 5 && ( 
    <Box sx={{marginTop:'20px',paddingLeft:'10px', flexGrow: 1,}}>
    <TextField
    label="Search"
    variant="outlined"
    value={searchText}
    onChange={(e) => setSearchText(e.target.value)}
    style={{ marginBottom: '16px' }}
   />
   <TableContainer>
    <Table>
      <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
        <TableRow>
        <TableCell>#</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Phone Number</TableCell>
          <TableCell>Property</TableCell>
          <TableCell>Comment</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{backgroundColor: colors.primary[700]}}>
        {filteredData
          .map((row, index) => (
            <TableRow key={row.id}>
            </TableRow>
          ))}
      </TableBody>
    </Table>
   </TableContainer>
   <TablePagination
    rowsPerPageOptions={[10,25,50,100]}
    component="div"
    count={totalItems}
    rowsPerPage={controller.rowsPerPage}
    page={controller.page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
   />
   </Box>    
)}
{selectedTab === 6 && ( 
      <Box sx={{marginTop:'20px',paddingLeft:'10px', flexGrow: 1,}}>
                        <Box display='flex' justifyContent='right'>
        <Button variant='contained'
    sx={{
      backgroundColor: colors.blueAccent[600],
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
      mr: "15px;"
    }}
    onClick={() => setStageModalOpen(true)}>
      <AddIcon sx={{ mr: "5px" }} /> Add Stage</Button>
      </Box>

      <TextField
    label="Search"
    variant="outlined"
    value={searchStageText}
    onChange={(e) => setSearchStageText(e.target.value)}
    style={{ marginBottom: '16px' }}
   />
   <TableContainer>
    <Table>
      <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
        <TableRow>
        <TableCell>#</TableCell>
            <TableCell>Stage</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{backgroundColor: colors.primary[700]}}>
        {filteredStageData
          .map((row, index) => (
            <TableRow key={row.id}>
            <TableCell>{row.id}</TableCell>
           <TableCell>{row.stage}</TableCell>
           <TableCell>{row.date ? row.date.slice(0, -16).replace('T', ' ') : (row.date)}</TableCell>
           <TableCell><Button key={row.id} variant='contained' sx={{backgroundColor: colors.blueAccent[600]}}><VisibilityIcon/></Button></TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
   </TableContainer>
   <TablePagination
    rowsPerPageOptions={[10,25,50,100]}
    component="div"
    count={totalStageItems}
    rowsPerPage={controllerStage.rowsPerPage}
    page={controllerStage.page}
    onPageChange={handleChangeStagePage}
    onRowsPerPageChange={handleChangeRowsStagePerPage}
    sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
   />
     <Modal
open={stageModalOpen}
onClose={() => setStageModalOpen(false)}
aria-labelledby="modal-title"
aria-describedby="modal-description">
<Box backgroundColor={colors.primary[700]} sx={style}>
<div className="modal-content">
    <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    <h3 id="modal-title">Add Stage</h3>
    <Box display="flex" justifyContent="right">
<CancelIcon onClick={() => setStageModalOpen(false)} sx={{cursor:"pointer"}}/>
</Box>
    </Box>
<form onSubmit={handleStageSubmit}>
<Grid container spacing={1}>
<Grid item xs={12}>
    <TextField
    fullWidth    
        type="text"
        label="Stage"
        placeholder="Stage"
        name="stage"
        onChange={handleInputChange}
        required="true"
    />
</Grid>
</Grid> 
    <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white',backgroundColor: colors.blueAccent[600]}}>Add Stage</Button>
    </form>
</div>
</Box>
</Modal>
     </Box>  
)}


      <Modal
        open={commentopen}
        onClose={() => setCommentOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add Comment</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setCommentOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
<form onSubmit={handleNewCommentSubmit}>
            <TextField
            multiline
            rows={3}
            label="Ticket Comment"
            placeholder="Ticket Comment"
            variant="outlined"
            fullWidth
            name="comment"
            onChange={handleInputChange}
            sx={{marginTop:"5px"}}
          />
          
        
          <TextField    
              type="hidden"
              name="ticket_id"
              value={selectedItemComment}
              style={{ display: 'none' }}
              required="true"
            />
    
          <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white',backgroundColor: colors.blueAccent[600]}}>Submit Comment</Button>
          </form>
            </div>
            </Box>
            </Modal>


            <Modal
        open={commentlistopen}
        onClose={() =>setCommentListOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Comment List For Ticket No #{selectedItem && (selectedItem.id)}</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setCommentListOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>


<Box sx={{height:'300px',overflowY:'auto'}}>
{comments.map((cmmnts,i) => (
  <Box variant="h5" p={2} m={1} sx={{backgroundColor: colors.primary[600], borderRadius:'5px'}}><Box>{cmmnts.comment}</Box> <br/> <Box display='flex' justifyContent='right'>{cmmnts.created_at.slice(0, -9).replace('T', ' ')}</Box></Box>
))}
</Box>

    

            </div>
            </Box>
            </Modal>




    </div>

  </Box>
  );
}

export default Ticketing;

