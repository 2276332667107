import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Modal,
    Box,
    Typography,
    Grid,
    Tab,
    Tabs,
  } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ColorModeContext, tokens } from "../theme";
import axios from 'axios';
import { global_link } from '../Components/Globallink';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const KnowledgeBase = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selectedTab, setSelectedTab] = useState(0);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [scriptopen, setScriptOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [selectedItem, setSelectedItem] = useState(null);
    const [scripts, setScripts] = useState(null);
    const [scripteditopen, setScriptEditOpen] = useState(false);
    const [scripteditopenreadonly, setScriptEditOpenReadonly] = useState(false);

    const [searchScriptValue, setSearchScriptValue] = useState('');
    const [searchScriptResults, setSearchScriptResults] = useState([]);

    
  
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        borderRadius: '10px',
        boxShadow: 24,
        p: 3,
      }

const api = axios.create({baseURL: global_link});

const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleScriptSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    api.post('/addscript/', formData,{ withCredentials: true})
      .then((response) => {
        setScriptOpen(false);
        setScriptResultsUp();
      })
      .catch((error) => {
        console.error(error);
      });
   }


   const handleScriptEditSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const id = formData.get('id');
    api.put(`/updatescript/${id}`, formData,{ withCredentials: true})
      .then((response) => {
        setScriptResultsUp();
        setScriptEditOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const handleSearchScriptChange = (event) => {
    const searchvalue = event.target.value;
    setSearchScriptValue(searchvalue);
    const accessToken = localStorage.getItem('access_token');
    const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',};
    api.get('/allscript/', {
      headers,
      withCredentials: true,
    })
      .then(response => {
        const scriptresultdata = response.data;
        const filteredScriptResults = scriptresultdata.filter(item =>
          item.header.toLowerCase().includes(searchvalue.toLowerCase()) || 
          item.script.toLowerCase().includes(searchvalue.toLowerCase()) || 
          item.department.toLowerCase().includes(searchvalue.toLowerCase())
        );
      
        setSearchScriptResults(filteredScriptResults);
      })
      .catch(error => {
        console.error(error);
      });
  };


  const setScriptResultsUp = (event) => {
        const searchvalue = "";
        setSearchScriptValue(searchvalue);
        const accessToken = localStorage.getItem('access_token');
        const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',};
        api.get('/allscript/', {
          headers,
          withCredentials: true,
        })
          .then(response => {
            const scriptresultdata = response.data;
            const filteredScriptResults = scriptresultdata.filter(item =>
              item.header.toLowerCase().includes(searchvalue.toLowerCase()) || 
              item.script.toLowerCase().includes(searchvalue.toLowerCase()) || 
              item.department.toLowerCase().includes(searchvalue.toLowerCase())
            );
          
            setSearchScriptResults(filteredScriptResults);
          })
          .catch(error => {
            console.error(error);
          });
      };

      useEffect(() => {
        setScriptResultsUp();
        },[]);


  const handleRowClick = (item) => {
    setSelectedItem(item);
    setScriptEditOpen(true);
  };

  // const handleRowClickReadOnly = (item) => {
  //   setSelectedItem(item);
  //   setScriptEditOpenReadonly(true);
  // };

  const handleInputModChange = (event) => {
    const { name, value } = event.target;
    setSelectedItem((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));
  };



  const handleScriptDelete = (id) => {
    api.delete(`/deletescript/${id}`, { withCredentials: true })
      .then((response) => {
        setScriptResultsUp();
        setScriptEditOpen(false);
      })
      .catch((error) => {
        setScriptEditOpen(true);
      });
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3 }}>
        <TokenExpiration/>
        <Expiration/>
    <Typography variant="h6" paddingBottom={2}> Knowledge Base</Typography>
    <Box display="flex" justifyContent="space-between">
<Box>
<Grid>
<Grid item xs={12}>
<Tabs
  value={selectedTab}
  onChange={handleTabChange}
  textColor="secondary"
  indicatorColor="secondary"
  variant="fullWidth"
      >
        <Tab label="How to Download the App"/>
        <Tab  label="Registering Your Account"/>
        <Tab  label="Adding Money to Your Payless Wallet"/>
        <Tab  label="Understanding Your Wallet Balance"/>
        <Tab  label="Using Payless QRs"/>
        <Tab  label="Transactions (Sending Money, Paying Bills, Paying Merchants)"/>
        <Tab  label="Help and Support"/>
        <Tab  label="Common Errors"/>
        <Tab  label="Troubleshooting"/>
        <Tab  label="Tutorials"/>
      </Tabs>
        </Grid>
        </Grid>
    </Box>

    <Box>
    <Button
        variant='contained'
        sx={{
          backgroundColor: colors.blueAccent[600],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
          mr: "15px;",
          display:'none',
        //   display: sessionStorage.getItem("user_level")==='supervisor' || sessionStorage.getItem("user_level")==='admin' ? '' : 'none',
        }}
        onClick={() => setScriptOpen(true)}
      >
        <AddIcon sx={{ mr: "10px" }} />
        Add Script
      </Button>
      </Box>
    </Box>



    {selectedTab === 0 && <span>
        <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Where can I find the Payless App for download? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      The Payless App is available on the Google Play Store for Android users and the Apple App Store for iOS users.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Is the Payless App free to download?</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Yes, the Payless App is free to download from both the Google Play Store and the Apple App Store.
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How do I install the Payless App on my Android device? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Open the Google Play Store, search for "Payless App," tap on the app from the search results, and click "Install."
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What are the steps to install the Payless App on an iOS device? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Launch the Apple App Store, search for "Payless App," select the app, tap “Get,” then "Install." Authenticate with your Apple ID if required.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Can I use the Payless App on multiple devices? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Yes, you can install the Payless App on multiple devices, but you may need to verify your account on each device for security purposes.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Do I need an internet connection to download the Payless App? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Yes, an active internet connection is required to download the Payless App from the Google Play Store or Apple App Store.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Is the Payless App compatible with all mobile devices? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      The Payless App is compatible with most modern smartphones. However, compatibility might vary depending on your device's operating system version.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Will the Payless App download automatically after clicking "Install"? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Yes, once you click "Install," the Payless App will automatically download and install on your device.
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What should I do if I encounter issues while downloading the Payless App? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Check your internet connection and ensure sufficient storage space on your device. If issues persist, contact Payless App support for assistance.
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Can I use the Payless App immediately after installation? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Yes, you can open the Payless App and begin registration immediately after installation.
      </Typography>
    </AccordionDetails>
  </Accordion>
  
    </span>}



 {selectedTab === 1 && <span>
    <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How do I register for a new account on the Payless App?</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Open the app, select the option to register, and provide your mobile phone number and email address.
      </Typography>
    </AccordionDetails>
  </Accordion>



  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What formats of phone numbers are accepted for registration? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      The Payless App accepts phone numbers in these formats: +2540XXXXXXXX, 2540XXXXXXXXX, or 0XXXXXXXXX.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Is an email address mandatory for registering on the Payless App? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Providing an email address is optional during registration, but it’s recommended for account recovery purposes.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What is an OTP and why do I need it for registration?</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      OTP stands for One-Time Password. It's a security measure used to verify your phone number during registration.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How long does it take to receive the OTP?</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      The OTP is usually sent immediately after you enter your phone number. If there's a delay, request to resend the OTP.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What should I do if I don’t receive the OTP? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Check if you entered the correct phone number. If the number is correct, try resending the OTP or contact customer support for help.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How do I set up a Login PIN? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      After verifying your mobile number with the OTP, follow the prompts in the app to set up a secure Login PIN.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What happens if I forget my Login PIN?</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      If you forget your Login PIN, you can reset it through the app using your registered phone number or email address.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Is my personal information safe during registration? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Yes, Payless takes data security seriously. Your personal information is encrypted and stored securely.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Can I change the phone number associated with my Payless account? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Yes, you can change your phone number in the account settings section of the app, subject to verification procedures.
      </Typography>
    </AccordionDetails>
  </Accordion>

    </span>}


    {selectedTab === 2 && <span>
        <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How do I add money to my Payless Wallet using MPESA? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Select "Add Money" in the app, choose the MPESA option, enter your phone number, and authorize the transaction with your MPESA PIN.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Can I top up my Payless Wallet via bank transfer? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Yes, select "Add Money" in the app, choose the "Bank" option, and follow the instructions to transfer money from your bank account to the Payless account.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How long does it take for a bank transfer to reflect in my Payless Wallet?</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      The processing time for bank transfers can vary. If the balance doesn’t update promptly, wait a few moments or contact customer support.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Are there any fees for adding money to my Payless Wallet?</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Fees can vary based on the method used for adding money. Check the app for any applicable fees or contact customer support for details.
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Is there a limit to how much money I can add to my Payless Wallet?</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Yes, there are transaction limits for adding money to your wallet. These limits are available in the app's terms and conditions.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What should I do if I encounter an error while adding money?</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Check your internet connection, confirm the transaction details, and try again. If the issue persists, contact customer support.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Can I add money to my Payless Wallet from an international bank account? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      This depends on Payless’s policy on international transactions. Please check the app’s guidelines or contact customer support for clarification.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How do I check if my wallet top-up was successful? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      After adding money, you should receive a confirmation in the app and an updated balance. You can also check your transaction history for confirmation.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Can I cancel a wallet top-up transaction? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Once a transaction is processed, it typically cannot be canceled. Double-check all details before confirming a top-up.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What is the minimum amount I can add to my Payless Wallet?</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      The minimum top-up amount is specified in the app. Check the “Add Money” section for the current minimum amount.
      </Typography>
    </AccordionDetails>
  </Accordion>

</span>}


{selectedTab === 3 && <span>
    <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How can I check my current wallet balance in the Payless App? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      To view your wallet balance, navigate to the app's homepage and click on the eye icon to reveal your balance.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What should I do if my wallet balance appears incorrect? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Refresh your app to update the balance. If it still appears incorrect, review your recent transactions for any discrepancies.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How can I access my transaction history in the Payless App? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Go to the transaction history section in the app to view all your transactions, including payments made and received.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Can I filter my transaction history for specific dates or transactions? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Yes, you can use filters like transaction ID, mobile number, or date to search for specific transactions in your history.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How do I request a transaction statement from the Payless App? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Click on "Request Statement" in the app, select the time duration, confirm your email ID, and follow the prompts to receive your statement.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How long does it take to receive a requested transaction statement? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      The time to receive a transaction statement may vary. Check your email after making the request, and if there's a delay, contact customer support.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Is there a charge for requesting a transaction statement?</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      No, there's no charge for requesting a transaction statement.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Can I download my transaction history for personal record-keeping? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Yes, Check the transaction history section for options.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What should I do if a transaction is missing from my history? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      If a transaction is missing, double-check the transaction details and date filters. If it's still missing, contact customer support.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How often should I check my wallet balance and transaction history? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      It's good practice to regularly check your wallet balance and transaction history to stay informed about your finances and spot any unauthorized activity early.
      </Typography>
    </AccordionDetails>
  </Accordion>


</span>}


{selectedTab === 4 && <span>
    <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How do I scan a QR code to make a payment in the Payless App? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Tap the "Scan QR" button in the app, align the QR code within the scanner's viewfinder, and follow the prompts to complete the payment.
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Can I scan any QR code for payment in the Payless App? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      The Payless App's QR scanner is designed to work with Payless issued QR codes.
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How do I generate my personal QR code in the Payless App? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Navigate to the QR code section in the app and follow the prompts to generate your personal QR code for receiving payments.
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Is it safe to share my Payless QR code with others? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Yes, it's safe to share your Payless QR code. It only allows others to make payments to your account.
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What should I do if the QR code scanner is not working? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Check for obstructions or poor lighting conditions. Use the flashlight feature if needed or try the gallery option to scan a saved QR code image.
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Can I use the QR code for transactions with non-Payless users? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Currently, the QR code feature is designed for transactions within the Payless ecosystem.
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How quickly are payments processed through QR code transactions?</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      QR code transactions are typically processed instantly. Check your transaction history for confirmation..
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What if the QR code scan results in an error? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Ensure the QR code is a Payless issued QR code and that it's clear and unobstructed. If errors persist, contact customer support.
      </Typography>
    </AccordionDetails>
  </Accordion>

</span>}


{selectedTab === 5 && <span>
    <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How do I send money to another Payless customer? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Use the "Send to Payless" option in the app, enter the recipient's details, the amount, and confirm the transaction with your PIN.
      </Typography>
    </AccordionDetails>
  </Accordion>



  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What steps do I follow to pay utility bills through the Payless App? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Select the bill payment option, choose the service (e.g., PayTV), enter the account number, confirm the bill amount, and complete the payment.
      </Typography>
    </AccordionDetails>
  </Accordion>


  
  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>ow can I pay merchants using the Payless App? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      For MPESA merchant payments, select "Till" or "Paybill," enter the required details, and authorize the payment with your PIN.
      </Typography>
    </AccordionDetails>
  </Accordion>


  
  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Are there any fees for sending money to another Payless customer?</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Transaction fees, if any, will be displayed in the app during the transaction process.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How do I send money to an MPESA user from the Payless App? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Choose the MPESA transfer option, enter the recipient’s phone number and amount, and confirm with your PIN.
      </Typography>
    </AccordionDetails>
  </Accordion>


  
  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Can I pay for data bundles through the Payless App? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Yes, select the data bundle option, choose the provider, enter the destination phone number, and proceed with payment.
      </Typography>
    </AccordionDetails>
  </Accordion>


  
  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What is the transaction limit for sending money through the Payless App? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Transaction limits are provided in the app’s terms and conditions or FAQ section.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How long does it take for a bill payment to reflect in the Payless App?</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Bill payments are typically processed immediately, but the time may vary depending on the service provider.
      </Typography>
    </AccordionDetails>
  </Accordion>
  
  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Can I cancel a bill payment or money transfer after confirming it? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Once confirmed, most transactions cannot be canceled. Always double-check the details before confirming a transaction.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What should I do if my transaction to pay a bill or send money fails?</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Check for any error messages, ensure sufficient funds, and retry. If the issue persists, contact customer support.
      </Typography>
    </AccordionDetails>
  </Accordion>

  
</span>}


{selectedTab === 6 && <span>
    <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How do I raise a support ticket in the Payless App?</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Go to the "Help and Support" section in the app, click "Raise a Support Issue," fill out the form with your issue details, and submit.
      </Typography>
    </AccordionDetails>
  </Accordion>



  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What information should I include when raising a support ticket? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Provide specific details like transaction IDs, error messages, and a clear description of your issue for quicker resolution.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How can I contact Payless App customer support directly? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      For immediate assistance, call the customer support number provided in the app, or send an email to support@payless.africa
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How do I access the knowledge base in the Payless App? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Navigate to the "Help and Support" section and look for the knowledge base to find articles and videos.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What should I do in case of an emergency or suspected fraud? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      For urgent issues like suspected fraud, use the direct call channel provided in the app for immediate assistance.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Are there any charges for contacting customer support in the Payless App? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Generally, there are no charges for contacting customer support, but standard call rates may apply for phone support.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How can I follow up on a previously raised support ticket? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      You can follow up on your support ticket through the "Help and Support" section in the app or by contacting customer care.
      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>What should I do if I’m not satisfied with the resolution provided by customer support? </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      If you're not satisfied, you can request further escalation of your issue or provide feedback for improvement.
      </Typography>
    </AccordionDetails>
  </Accordion>


</span>}



{selectedTab === 7 && <span>
  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>PIN Match Error</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Advise the customer to input correct pin. If on sign up/initial set up, customer should use the same PIN on both slots</li>
      <li>On sign up</li>
      <li>If customer insists PIN is correct but error persists, advise customer to use Forgot PIN to create a new PIN</li>
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Your KYC request has been rejected</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Advise the customer to enter the correct details as per the national ID</li>
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Your KYC request is under review due to some reason </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Advise customer to be patient, KYC will be approved in a few minutes</li>
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Mobile Number Doesn’t Exist</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Advise customer to use the exact number he/she registered with. Alternatively, customer can register the new number</li>
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Entered OTP is incorrect. Please enter correct OTP </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Advise the Customer to confirm the OTP and enter correct one. Alternatively, generate another OTP</li>
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Unable to Upload ID doc for KYC upgrade</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Advise the customer to take a photo of the original ID, not photocopy of ID or photo of random documents e.g. DL. Note that there is no option for attaching ID from downloads</li>
      </Typography>
    </AccordionDetails>
  </Accordion> 
  
  </span>}


{selectedTab === 8 && <span>
  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>1. OTP ISSUES</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Troubleshooting
      <li>Ask customer if they are able to receive other SMS besides payless, if they cannot receive any SMS, it is a handset issue</li>
      <li>Ask customer to check if the SMS is on SPASM/blocked messages</li>
      <li>Ensure customer has not blocked promotional Messages</li>
      <li>If the customer is using a dual sim, confirm the number customer is using is ON/enabled</li>
      <li>If all the above fail, escalate and advise customer on SLA </li>
      <li>Advise the customer to take a photo of the original ID, not photocopy of ID or photo of random documents e.g. DL. Note that there is no option for attaching ID from downloads</li>

      <br/>
      Escalation
      <li>Get type of handset customer is using</li>
      <li>Get the number customer is registering on the App</li>
      <li>Ask the customer if there is an error message and capture it</li>
      <li>Capture all the above on description and advise customer on SLA</li>

      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>2.	Transaction Issues(Declined/Failed/pending)</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>


      Troubleshooting
      <li>Confirm from BO if customer is registered up to level 3</li>
      <li>Confirm that the customer's account is not suspended/inactive/dormant/Blocked</li>
      <li>Confirm that the customer has sufficient funds (inclusive of transaction fees)</li>
      <li>Confirm the type of transaction (On-net, Bank, Airtime etc.)</li>
      <li>Confirm amount involved is within allowed maximum limit, if not advise customer on acceptable limits</li>
      <li>Confirm if the funds have already been deducted from the customers payless Wallet and if not, customer can retry</li>
      <li>If all the above is OK, escalate</li>
      <br/>
     Escalation
        <li>Confirm if there is an error message and capture in on the escalation</li>
        <li>Indicate type of transaction and amount</li>
        <li>capture the number registered on the App</li>
        <li>Advise customer on SLA</li>

     </Typography>
    </AccordionDetails>
  </Accordion>



  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>3. SUSPENDED ACCOUNT</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Check if there is a comment/reason for suspension and advise the customer accordingly. If no reason is captured, Escalate. Capture the suspended number and an alternative contact</li>
     </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>  4.	ADD MONEY ISSUES (unable to add, Money added but balance not updated)</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Troubleshooting
      <li>Confirm if customer is adding from M-PESA, Bank or Primary Wallet</li>
      <li>Confirm the error the customer is getting</li>
      <li>Confirm from the customer if source has sufficient balance inclusive of charges</li>
      <li>Confirm if the funds have already been deducted from the source, if not, customer can retry</li>
      <li>Confirm that the payless wallet balance is within limit, if not advise customer on acceptable limits</li>
      <li>If all above fails, escalate</li>
</Typography>
    </AccordionDetails>
  </Accordion>
  </span>}


{selectedTab === 9 && <span>
  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How to sign up on the App</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Click on 'Don't have a registered Account? Signup' Link</li>
<li>Sign up by filling the below</li>
<li>Mobile Number</li>
<li>Email ID (Optional)</li>
<li>Then click GET OTP Button</li>
<li>You will receive a 4 - digit OTP on SMS, Enter the OTP and click on the verify button</li>
<li>You will be redirected to Set PIN screen</li>
<li>Enter a preferred 4-digit pin, re-enter the PIN then click Sign up Button</li>
<li>You will get a message 'PIN Set successfully' and Enable biometrics check box with Proceed Button</li>
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How to complete KYC level 2</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Login with your registered number and click on any icon</li>
      <li>Select ID Type from the dropdown</li>
      <li>Enter ID/PASSPORT depending on selected type</li>
      <li>Click on Proceed Button then fill in the below</li>
      <li>First Name</li>
      <li>Last Name</li>
      <li>DOB</li>
      <li>Gender</li>
      <li>If the details are correct, Click Proceed to continue</li>
      </Typography>
    </AccordionDetails>
  </Accordion>
  </span>}



    <Modal
        open={scriptopen}
        onClose={() => setScriptOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add Script / Reminder</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setScriptOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
<form onSubmit={ handleScriptSubmit}>


      <TextField
            label="Department"
            placeholder="Department"
            variant="outlined"
            fullWidth
            name="department"
            onChange={handleInputChange}
            sx={{marginTop:"15px"}}
          />

<TextField
            label="Header"
            placeholder="Header"
            variant="outlined"
            fullWidth
            name="header"
            onChange={handleInputChange}
            sx={{marginTop:"15px"}}
          />

            <TextField
            multiline
            rows={6}
            label="Script"
            placeholder="Script"
            variant="outlined"
            fullWidth
            name="script"
            onChange={handleInputChange}
            sx={{marginTop:"15px"}}
            required="true"
          />
          
        
    
          <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white',backgroundColor: colors.blueAccent[600]}}>Submit Script</Button>
          </form>
            </div>
            </Box>
            </Modal>





            
    <Modal
        open={scripteditopen}
        onClose={() => setScriptEditOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Edit Script</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setScriptEditOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
           
<form onSubmit={handleScriptEditSubmit}>
{selectedItem && ( 
    <Grid container spacing={1}>

<Grid item xs={12}>
<TextField
        fullWidth
        label="Department"
        name="department"
        onChange={handleInputModChange}
        sx={{marginTop:"5px"}}
        value={selectedItem.department || ""}
      />
</Grid>

<Grid item xs={12}>
<TextField
        fullWidth
        label="Header"
        name="header"
        onChange={handleInputModChange}
        sx={{marginTop:"5px"}}
        value={selectedItem.header || ""}
      />
</Grid>

<Grid item xs={12}>
            <TextField
            multiline
            rows={8}
            label="Script"
            placeholder="Script"
            variant="outlined"
            fullWidth
            name="script"
            onChange={handleInputModChange}
            value={selectedItem.script || ""}
            sx={{marginTop:"15px"}}
            required="true"
          />

<TextField
            variant="outlined"
            fullWidth
            name="id"
            onChange={handleInputModChange}
            value={selectedItem.id || ""}
            sx={{display:"none"}}
            required="true"
            type="hidden"
          />
          </Grid>

          <Grid container item xs={12} sx={{display:'flex', justifyContent:'space-between'}}>

<Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white',backgroundColor: colors.blueAccent[600]}}>Edit Script</Button>
<Button type="button" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white',backgroundColor: 'red'}} onClick={() => handleScriptDelete(selectedItem.id)}>Delete</Button>

</Grid>
          </Grid>
          )}


    
          

</form>
            </div>
            </Box>
            </Modal>





            <Modal
        open={scripteditopenreadonly}
        onClose={() => setScriptEditOpenReadonly(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">{selectedItem && (selectedItem.department)} Script</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setScriptEditOpenReadonly(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
            
{selectedItem && ( 
<Grid container spacing={1}>
<Grid item xs={12}><br/>
<h2>{selectedItem.script}</h2>
</Grid></Grid>
          )}

            </div>
            </Box>
            </Modal>





    </Box>
  );
};

export default KnowledgeBase;
