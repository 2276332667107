import React, { useState,useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Tabs, Tab, Typography, Box, Divider, Grid, TextField, Button} from '@mui/material';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';
import { ColorModeContext, tokens } from "../theme";
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import { global_link } from '../Components/Globallink';
import axios from 'axios';

const Analytics = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

  const [selectedTab, setSelectedTab] = useState(0);
  const [formData, setFormData] = useState({});

  const [callDates, setCallDates] = useState([]);
  const [allCallReport, setAllCallReport] = useState([]);
  const [callLabels, setCallLabels] = useState([]);

  const [talkDates, setTalkDates] = useState([]);
  const [talkData, setTalkData] = useState({});
  const [allTalkReport, setAllTalkReport] = useState([]);
  const [talkLabels, setTalkLabels] = useState([]);

  const [allPerformanceReport, setAllPerformanceReport] = useState([]);
  const [performanceDates, setPerformanceDates] = useState([]);
  const [performanceLabels, setPerformanceLabels] = useState([]);
  


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const api = axios.create({
    baseURL: global_link
});

const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormData((prevFormData) => ({
    ...prevFormData,
    [name]: value,
  }));
};

const generateRandomColor = (count) => {
  const colors = [];
  for (let i = 0; i < count; i++) {
    const randomColor = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, 0.6)`;
    colors.push(randomColor);
  }
  return colors;
};


function generateChartColors(count) {
  const baseColors = [
    '#FFB643','#91a832','#F66F8D','#FF5722','#5041c6',
    '#0a2ab2','#53827b','#7aaf80','#91a248','#8a0cf1','#a6dcdc',
    '#ac3805','#16186f','#67d830','#c0ece2','#00acca','#ce014c',
    '#936fd5','#a38e46','#3611ab','#646656','#0771c3','#08c77c',
    '#72797e','#e5adf6','#156ec9','#22d3d4','#22d3d4','#35b899',
    '#5903db','#33777b','#b0845f','#ca7582','#ca57dd','#e1794a',
    '#cd66f3','#b9b935','#b9b935','#2acb07','#6c650f','#9d64fd',
    '#87ca91','#aa9484','#9cf379','#2235a4','#12a96b','#9303cc',
    '#2cf1be','#5c9a41','#09ed09','#12275e','#3703e2','#a167e8',
    '#235b57','#def268','#836f25','#8a0e82','#68d619','#2b9d6a',
    '#1e155a','#73dd53','#dc1b5f','#ad7a4b',
  ];
  const colors = [];

  for (let i = 0; i < count; i++) {
    const baseColorIndex = i % baseColors.length;
    colors.push(baseColors[baseColorIndex]);
  }

  return colors;
}


const handleTicketReportSubmit = async (event) => {
  event.preventDefault();
  const formData = new FormData(event.target);
  const startDate = new Date(formData.get('start_date'));
  const endDate = new Date(formData.get('end_date'));
  const timeDifference = endDate.getTime() - startDate.getTime();
  const daysDifference = timeDifference / (1000 * 3600 * 24);


  const accessToken = localStorage.getItem("access_token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };
  if(daysDifference<31){
    try {
      const response = await api.get('/callreports/', {
        params: {
          keyword: formData.get('keyword'),
          start_date: formData.get('start_date'),
          end_date: formData.get('end_date'),
        },
        headers,
        withCredentials: true,
      });
  
      console.log(response.data); // Log the API response
  
      if (response.data.length > 0) {
        // Group and count call_duration and call_type combinations
        const dataCounts = response.data.reduce((acc, item) => {
          let key;
          if (item.call_duration.match(/^([0-5]?\d):([0-5]?\d):([0-5]?\d)$/)) {
            key = 'Received';
          } else {
            key = `${item.call_duration}`;
          }
          const dateKey = item.call_time.slice(0, 10);
  
          if (!acc[dateKey]) {
            acc[dateKey] = {};
          }
  
          acc[dateKey][key] = (acc[dateKey][key] || 0) + 1;
  
          return acc;
        }, {});
  
        // Extract unique categories and their counts
        const uniqueCategories = Object.keys(dataCounts);
        const categoryCountsArray = uniqueCategories.map(date => {
          return {
            Received: dataCounts[date]['Received'] || 0,
            'Tried Calling': dataCounts[date]['Tried Calling'] || 0,
            'Not Found': dataCounts[date]['Not Found'] || 0,
            Missed: dataCounts[date]['Missed'] || 0,
          };
        });
  
        // Extract unique dates
        const uniqueDates = Object.keys(dataCounts);
  
        // Update state with unique categories and counts
        setAllCallReport(categoryCountsArray);
        setCallDates(uniqueDates);
        setCallLabels(['Received', 'Tried Calling', 'Not Found', 'Missed']);
      } else {
        // Handle empty data or show a message
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error or show an error message
    }
  }


};




const handleTalkTimeSubmit = async (event) => {
  event.preventDefault();
  const formData = new FormData(event.target);
  const startDate = new Date(formData.get('start_date'));
  const endDate = new Date(formData.get('end_date'));
  const timeDifference = endDate.getTime() - startDate.getTime();
  const daysDifference = timeDifference / (1000 * 3600 * 24);
  const accessToken = localStorage.getItem("access_token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  if(daysDifference<31){
    try {
      const response = await api.get('/talktimereports/', {
        params: {
          keyword: formData.get('keyword'),
          start_date: formData.get('start_date'),
          end_date: formData.get('end_date'),
        },
        headers,
        withCredentials: true,
      });
  
      console.log(response.data);
      if (response.data.length > 0) {
        const dataDurations = response.data.reduce((acc, item) => {
          const dateKey = item.call_time.slice(0, 10);
      
          if (!acc[dateKey]) {
            acc[dateKey] = 0;
          }
      
          const callDuration = item.call_duration;
          const seconds = getSecondsFromHHMMSS(callDuration);
      
          console.log(`Date: ${dateKey}, Call Duration: ${callDuration}, Seconds: ${seconds}`);
          acc[dateKey] += seconds;
      
          return acc;
        }, {});
      
        const uniqueDates = Object.keys(dataDurations);
      
        // Convert total seconds to 'hh:mm:ss' format
        const formattedDurations = uniqueDates.map(date => {
          const totalSeconds = dataDurations[date];
          return secondsToHHMMSS(totalSeconds);
        });
      
  
        // console.log('Unique Dates:', uniqueDates);
        // console.log('Formatted Durations:', formattedDurations);
      
        // const talkdata = {
        //   labels: uniqueDates,
        //   datasets: [
        //     {
        //       label: 'Talk Time',
        //       data: formattedDurations,
        //       backgroundColor: '#FFB643',
        //     },
        //   ],
        // };
  
  
        const talkdata = {
          labels: uniqueDates,
          datasets: [
            {
              label: 'Talk Time (Secs)',
              data: uniqueDates.map(date => dataDurations[date]), // Provide the duration in seconds
              backgroundColor: '#73dd53',
            },
          ],
        };
      
        setTalkData(talkdata);
      } else {
        // Handle empty data or show a message
      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error or show an error message
    }
  }

};

// Helper function to convert 'hh:mm:ss' to seconds
const getSecondsFromHHMMSS = (duration) => {
  const [hours, minutes, seconds] = duration.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

// Helper function to convert seconds to 'hh:mm:ss' format
const secondsToHHMMSS = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
};



const calldata = {
  labels: callDates,
  datasets: [
    {
      label: 'Received',
      data: allCallReport.map(data => data['Received']),
      backgroundColor: '#FFB643',
    },
    {
      label: 'Tried Calling',
      data: allCallReport.map(data => data['Tried Calling']),
      backgroundColor: '#91a832',
    },
    {
      label: 'Not Found',
      data: allCallReport.map(data => data['Not Found']),
      backgroundColor: '#F66F8D',
    },
    {
      label: 'Missed',
      data: allCallReport.map(data => data['Missed']),
      backgroundColor: '#FF5722',
    },
  ],
};


const calloptions = {
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  plugins: {
    title: {
      display: false,
    },
    maintainAspectRatio: false,
  },
};


const talkoptions = {
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  plugins: {
    title: {
      display: false,
    },
    maintainAspectRatio: false,
  },
};

const handlePerformanceReportSubmit = async (event) => {
  event.preventDefault();
  const formData = new FormData(event.target);
  const accessToken = localStorage.getItem("access_token");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "Content-Type": "application/json",
  };

  const startDate = new Date(formData.get('start_date'));
  const endDate = new Date(formData.get('end_date'));
  const timeDifference = endDate.getTime() - startDate.getTime();
  const daysDifference = timeDifference / (1000 * 3600 * 24);

  if(daysDifference<31){
    try {
      const response = await api.get('/callperformancereport/', {
        params: {
          keyword: formData.get('keyword'),
          start_date: formData.get('start_date'),
          end_date: formData.get('end_date'),
        },
        headers,
        withCredentials: true,
      });
  
      console.log(response.data); // Log the API response
  
      if (response.data.length > 0) {
        // Group and count user_name and call_type combinations
        const dataCounts = response.data.reduce((acc, item) => {
          const userName = item.user_name;
          const dateKey = item.call_time.slice(0, -16).replace('T', ' ');
  
          if (!acc[userName]) {
            acc[userName] = {};
          }
  
          if (item.call_duration.match(/^([0-5]?\d):([0-5]?\d):([0-5]?\d)$/)) {
            acc[userName][dateKey] = (acc[userName][dateKey] || 0) + 1;
          }
  
          return acc;
        }, {});
  
        // Extract unique user_names and their counts
        const uniqueUserNames = Object.keys(dataCounts);
        const userCountsArray = uniqueUserNames.map(userName => {
          const receivedCount = Object.values(dataCounts[userName]).reduce((sum, count) => sum + count, 0) || 0;
          return {
            user_name: userName,
            Received: receivedCount,
          };
        });
  
        // Extract unique dates
        const uniqueDates = Array.from(new Set(response.data.map(item => item.call_time.slice(0, 10))));
  
        // Update state with unique user_names and counts
        setAllPerformanceReport(userCountsArray);
        setPerformanceDates(uniqueDates);
        setPerformanceLabels(uniqueUserNames);
      } else {
        // Handle empty data or show a message
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error or show an error message
    }
  }

};

// Dynamic datasets based on unique user_names
const performanceDatasets = performanceLabels.map((label, index) => ({
  label: label,
  data: allPerformanceReport.map(data => {
    // Assuming label is the user_name
    const userData = allPerformanceReport.find(user => user.user_name === label);
    return userData ? userData['Received'] : 0;
  }),
  backgroundColor: generateChartColors(performanceLabels.length)[index],
}));

const performancedata = {
  labels: performanceDates,
  datasets: performanceDatasets,
}



const performanceoptions = {
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  plugins: {
    title: {
      display: false,
    },
    maintainAspectRatio: false,
  },
};



  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3}}>
    <TokenExpiration/>
    <Expiration/>
  <Typography variant="h6" paddingBottom={2}>Analytics</Typography>
  <div>
    <Tabs value={selectedTab} onChange={handleTabChange}   textColor="secondary"
indicatorColor="secondary"
variant="fullWidth"
sx={{ backgroundColor: `${colors.primary[700]} !important`}}>
      <Tab label="Calls"/>
      <Tab label="Performance"/>
      <Tab label="Talk Time"/>
    </Tabs>
    <Divider/>

    {selectedTab === 0 && (

    <Box  alignItems="center">
       <br/>
       <form onSubmit={handleTicketReportSubmit}>      <Grid container spacing={2}  p={1} >
              <Grid item xs={4}>
        <label sx={{textAlign:'left'}}>Start Date</label>
    <TextField
          name="start_date"
          type="date"
          onChange={handleInputChange}
          fullWidth
          sx={{marginTop:"10px"}}
          variant="outlined"
          required
        />
    </Grid>


    <Grid item xs={4}>
        <label sx={{textAlign:'left'}}>End Date</label>
    <TextField
          name="end_date"
          type="date"
          onChange={handleInputChange}
          fullWidth
          sx={{marginTop:"10px"}}
          variant="outlined"
          required
        />
    </Grid>
    <Grid item xs={4}>
      <br/>
        <Button
          variant="contained"
          fullWidth
          type="submit"
          sx={{padding:'15px',marginTop:'10px!important',background: colors.greenAccent[700] }}

        >
         Generate
        </Button>
        </Grid>
    </Grid>

        </form>
    <br/>

            {/* <BarChart/> */}

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ width: '100%', height: '420px' }}>
        <Bar data={calldata} options={calloptions} />
      </div>
    </div>


            </Box>
    )}
 

 {selectedTab === 1 && (

<Box  alignItems="center">
   <br/>
   <form onSubmit={handlePerformanceReportSubmit}>      <Grid container spacing={2}  p={1} >
          <Grid item xs={4}>
    <label sx={{textAlign:'left'}}>Start Date</label>
<TextField
      name="start_date"
      type="date"
      onChange={handleInputChange}
      fullWidth
      sx={{marginTop:"10px"}}
      variant="outlined"
      required
    />
</Grid>


<Grid item xs={4}>
    <label sx={{textAlign:'left'}}>End Date</label>
<TextField
      name="end_date"
      type="date"
      onChange={handleInputChange}
      fullWidth
      sx={{marginTop:"10px"}}
      variant="outlined"
      required
    />
</Grid>
<Grid item xs={4}>
  <br/>
    <Button
      variant="contained"
      fullWidth
      type="submit"
      sx={{padding:'15px',marginTop:'10px!important',background: colors.greenAccent[700] }}

    >
     Generate
    </Button>
    </Grid>
</Grid>

    </form>
<br/>

        {/* <BarChart/> */}

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <div style={{ width: '100%', height: '420px' }}>
    <Bar data={performancedata} options={performanceoptions} />
  </div>
</div>


        </Box>
)}



{selectedTab === 2 && (

<Box  alignItems="center">
   <br/>
   <form onSubmit={handleTalkTimeSubmit}>      <Grid container spacing={2}  p={1} >
          <Grid item xs={4}>
    <label sx={{textAlign:'left'}}>Start Date</label>
<TextField
      name="start_date"
      type="date"
      onChange={handleInputChange}
      fullWidth
      sx={{marginTop:"10px"}}
      variant="outlined"
      required
    />
</Grid>


<Grid item xs={4}>
    <label sx={{textAlign:'left'}}>End Date</label>
<TextField
      name="end_date"
      type="date"
      onChange={handleInputChange}
      fullWidth
      sx={{marginTop:"10px"}}
      variant="outlined"
      required
    />
</Grid>
<Grid item xs={4}>
  <br/>
    <Button
      variant="contained"
      fullWidth
      type="submit"
      sx={{padding:'15px',marginTop:'10px!important',background: colors.greenAccent[700] }}

    >
     Generate
    </Button>
    </Grid>
</Grid>

    </form>
<br/>

        {/* <BarChart/> */}

        {Object.keys(talkData).length > 0 && (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ width: '100%', height: '420px' }}>
          <Bar data={talkData} options={talkoptions} />
        </div>
      </div>
    )}


        </Box>
)}

</div>
    </Box>
  );
};

export default Analytics;
