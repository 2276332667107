import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { styled, useTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import BackspaceIcon from '@mui/icons-material/Backspace';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { tokens } from "../theme";
import axios from 'axios';
import { global_link } from './Globallink';
import { useTimer } from './Timer';
import { useBreakTimer } from './BreakTimer';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import {
  TextField,
  Button,
  Grid,
  Tooltip,
  Modal,
  MenuItem,
} from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import Switch from '@mui/material/Switch';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import RedoIcon from '@mui/icons-material/Redo';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import {UA} from 'sip.js';

const api = axios.create({baseURL: global_link});

const DialBar = () => {

const theme = useTheme();
const colors = tokens(theme.palette.mode);

const [drawerWidth, setBarWidth] = useState(290);
const [display, setDisplay] = useState('');
const [selectedTab, setSelectedTab] = useState(0);
const [searchContactValue, setSearchContactValue] = useState('');
const [searchContactResults, setSearchContactResults] = useState([]);
const [openContactModal, setContactOpen] = useState(false);
const [formData, setFormData] = useState({});
const [searchCallValue, setSearchCallValue] = useState('');
const [searchCallResults, setSearchCallResults] = useState([]);
const [isDndChecked, setIsDNDChecked] = useState(false);
const [isAutoAnswerChecked, setIsAutoAnswerChecked] = useState(false);
const [isCallWaiting, setIsCallWaiting] = useState(false);
const [isTakeBreakEnabled, setIsTakeBreakEnabled] = useState(false);
const [isTakeBreakChecked, setIsTakeBreakChecked] = useState(false);
const isDisabled = localStorage.getItem("isDisabled") === "true";
const [isCallEnabledChecked, setIsCallEnabledChecked] = useState(!isDisabled);
const [isCallEnabledEnabled, setIsCallEnabledEnabled] = useState(false);
const [openTakingBreakModal, setTakingBreakOpen] = useState(false);
const [CallerName, setCallerName] = useState('');

const isBreakRef = useRef(false);
const callAccepted = useRef(false);
const isBreakType = useRef('');
const AutoAnswerRef = useRef(false);
const isDisableRef = useRef(!isDisabled);
const isMakingCall = useRef(false);
const incomingSessionRef = useRef(null);

// CONNECTION SETTINGS
const [isConnectedBackground, setConnectedBackground] = useState('#FF160C');
const [isConnectedTip, setIsConnectedTip] = useState('Disconnected');

// CALL INTERFACES
const [showDialer, setDialer] = useState(true);
const [showMakingCall, setMakingCall] = useState(false);
const [showCall, setShowCall] = useState(false);
const [showacceptedCall, setAcceptedCall] = useState(false);
const [remoteUri, setRemoteUri] = useState('');

const [isMuteOn, setIsMuteOn] = useState(false);
const [isHoldOn, setIsHoldOn] = useState(false);
const [isTransferOn, setIsTransferOn] = useState(false);
const [UserResults, setUserResults] = useState([]);

//SIP
const [registered, setRegistered] = useState(false);
const [callStatus, setCallStatus] = useState('');
const [isRinging, setRinging] = useState('MAKING CALL');

const incomingRingtoneRef = useRef(null);
const outgoingRingtoneRef = useRef(null);
const endcallRingtoneRef = useRef(null);
const callID = useRef(null);
const [isCallWaitingEnabled, setIsCallWaitingEnabled] = useState(false);
const [isAutoAnswerEnabled, setIsAutoAnswerEnabled] = useState(false);
const [disposition, setDisposition] = useState(null);
const {timer, startTimer, resetTimer} = useTimer();
const {breaktimer, startBreakTimer, resetBreakTimer} = useBreakTimer();
const [dispositionopen, setDispositionOpen] = useState(false);
const [dtmfopen, setDTMFOpen] = useState(false);
const [transferopen, setTransferOpen] = useState(false);
const [transferExtension, setTransferExtension] = useState(false);
const [callType, setCallType] = useState('');
const [BreakType, setBreakType] = useState('');
const [BreakBtn, setBreakBtn] = useState('');
const [BreakDrop, setBreakDrop] = useState('');
const [isDispositionHangupButtonVisible, setIsDispositionHangupButtonVisible] = useState('none');


const [ extrachange, setExtraChange] = useState('');
const [ dispositions, setDispositionChange] = useState('');
const [ dispositionnumber, setDispositionNumber] = useState('');
const [ dtmfno, setDtmfNo] = useState('');
const [ incomingStatus, setIncomingStatus] = useState('INCOMING CALL');



const [conferenceOpen, setConferenceOpen] = useState(false);
const [conferenceExtension,  setConference] = useState(false);


const [openServer, setOpenServer] = useState(false);
const handleServerClose = (event, reason) => {
if (reason === 'clickaway') {return;}setOpenServer(false);}; 
const [state, setState] = useState({openServer: false,vertical: 'top',horizontal: 'right',});
const { vertical, horizontal} = state;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  
});


useEffect(() => {
  // Update localStorage when state changes
  localStorage.setItem("isDisabled", !isCallEnabledChecked);
  // Update ref to avoid re-renders
  isDisableRef.current = !isCallEnabledChecked;
}, [isCallEnabledChecked]);


  // CATEGORIES
  const TicketCatgoryDataStatic = [
    { category: 'Login Issues', subcategories: ['OTP issues', 'App not Launching','Forgot Pin'] },
    { category: 'Transaction issues', subcategories: ['Wallet Verification Pending','Pending Transaction','STK push issues','Declined/Failed transactions ','Transaction charges disputes'] },
    { category: 'Reversal issues', subcategories: ['Airtime Reversal', 'Payless to Mpesa', 'Paylesa to Payless', 'Wrong Till Number', 'Payless to Bank'] },
    { category: 'Merchant issues', subcategories: ['Funds Not Reflecting', 'Till/Paybill not accepting payments','Unlisted Billers'] },
    { category: 'Account status', subcategories: ['Suspended', 'Inactive', 'Blocked', 'Restricted'] },
    { category: 'Loyalty points', subcategories: ['unable to redeem points', 'points not accumulating','Enrollment','Redeemable/ offers'] },
    { category: 'Fraud', subcategories: ['Conned', 'Attempted'] },
    { category: 'Feedback', subcategories: ['Suggestions','Complaints','Compliments'] },
    { category: 'Tariffs', subcategories: ['Transaction fees inquiries', 'Transaction charge disputes', 'Maximum/Minimum Limit Queries'] },
    { category: 'Statement', subcategories: ['How to get statement', 'Missing Transactions', 'Unable to retrieve','Certified statements'] },
    { category: 'Fraud Reports', subcategories: ['Conned','Unauthorized Access','Called by Fraudsters', 'Reporting a suspected Fraud','Physical Fraud'] },
    { category: 'App Downloading', subcategories: ['Android Version','IOS Version','App Identity Issues', 'App missing on Play Store'] },
    { category: 'Customer Onboarding ', subcategories: ['Sim Binding','KYC Verification Pending','OTP Issues','KYC Rejected'] },
    { category: 'Downtime issues ', subcategories: ['App Downtime/Maintenance','M-pesa downtime','Jambo Pay and other partners'] },
    { category: 'Customer support ', subcategories: ['Social media handles','WhatsApp number','Email address','Physical location'] },
    { category: 'Promotions and offers ', subcategories: ['Current promotions','Upcoming offers','Merchandise request'] },
    { category: 'Others', subcategories: ['Time queries','Other provider issues','NRG queries','Non-service related issues','Non-Compatible Devices'] }
    // Add more categories and subcategories as needed
  ];

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');

  const handleInputCategoryChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === 'category') {
      setSelectedCategory(value);
      setSelectedSubcategory(''); // Reset subcategory when category changes
    } else if (name === 'subcategory') {
      setSelectedSubcategory(value);
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
  };
  

//////// SIP JS 0.15 ////////
const [userAgent, setUserAgent] = useState(null);
const audioElementRef = useRef(null);
const currentCallRef = useRef(null);
const currentOutgoingCallRef = useRef(null);
const remoteAudioStreamRef = useRef(new MediaStream());
const userAgentRef = useRef(null);
const isOnCall = useRef(false);
//////// SIP JS 0.15 ////////

const accessToken = localStorage.getItem('access_token');
const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',};
const startTime = () => {
  startTimer();
};

const resetTime = () => {
  resetTimer();
};

const startBreakTime = () => {
  startBreakTimer();
};

const resetBreakTime = () => {
  resetBreakTimer();
};


const copyPhoneNumberLogs = (call_from, call_to) => {
  if(call_from==(sessionStorage.getItem('extension'))){
    const event = new CustomEvent('copyPhoneNumber', { detail: call_to });
    window.dispatchEvent(event);
  }else{
    const event = new CustomEvent('copyPhoneNumber', { detail: call_from });
    window.dispatchEvent(event);
  }
  
}

// RINGTONES
useEffect(() => {
  incomingRingtoneRef.current = new Audio('/ringtones/ringtone-126505.mp3');
  incomingRingtoneRef.current.loop = true;

  outgoingRingtoneRef.current = new Audio('/ringtones/phone-call-94426.mp3');
  outgoingRingtoneRef.current.loop = true;

  endcallRingtoneRef.current = new Audio('/ringtones/Call_Ended_Tone_Done.mp3');
  endcallRingtoneRef.current.loop = false;

  // Clean up
  return () => {
    stopIncomingTone();
    stopOutgoingTone();
    stopEndTone();
  };
}, []);


// HANNDLING AUTOANSWER
const handleAutoAnswer = (event) => {
  setIsAutoAnswerChecked(event.target.checked);
  setIsAutoAnswerEnabled(event.target.checked);
  if (event.target.checked) {
    setIsDNDChecked(false);
    AutoAnswerRef.current = true;
  }
};

// HANDLE CALL WAITING
const handleCallWaiting = (event) => {
  setIsCallWaiting(event.target.checked);
  setIsCallWaitingEnabled(event.target.checked);
};

// HANDLE TAKING BREAK
const handleTakingBreak = (event) => {
  setIsTakeBreakChecked(event.target.checked);
  setIsTakeBreakEnabled(event.target.checked);
  setIsAutoAnswerEnabled(true);
  setIsAutoAnswerChecked(false);
  if (event.target.checked) {
    isBreakType.current = 'Break';
    AutoAnswerRef.current = false;
    startBreakTime();
    setIsTakeBreakEnabled(true);
    setTakingBreakOpen(true);
    sessionStorage.setItem("break",`${new Date().getTime()}${Math.floor(Math.random() * 1000)}`);
  }
  startTime();
};



// DISABLE INCOMING CALLS
  const handleDisableCalls = (event) => {
    const isChecked = event.target.checked;
    setIsCallEnabledChecked(isChecked);
    localStorage.setItem("isDisabled", !isChecked);
    isDisableRef.current = !isChecked;
    // window.location.reload();
  };


  // GETTING USER INFO
useEffect(() => {
  const accessToken = localStorage.getItem('access_token');
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  const getUserInfo = async () => {
    try {
      const response = await api.get('/user/', {
        headers,
        withCredentials: true,
      });
      sessionStorage.setItem('extension', response.data.user_extension);
      sessionStorage.setItem('extensionpass', response.data.user_extension_pass);
    } catch (error) {
      console.error(error);
    }
  };

  const getServerInfo = async () => {
    try {
      const response = await api.get('/clientserver/', {
        headers,
        withCredentials: true,
      });

      sessionStorage.setItem('serverip', response.data.server_ip);
      sessionStorage.setItem('serverport', response.data.server_port);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      await Promise.all([getUserInfo(), getServerInfo()]);
    } catch (error) {
      console.error(error);
    }
  };
  fetchData();
}, []);



// ATTACHING MEDIA
const attachRemoteAudio = (session) => {
  session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
    if (receiver.track && receiver.track.kind === 'audio') {
      remoteAudioStreamRef.current.addTrack(receiver.track);
      // return;
    }
  });

  const audioElement = audioElementRef.current;
  audioElement.srcObject = remoteAudioStreamRef.current;
  audioElement.play().catch((error) => {
    console.error('Failed to play audio:', error);
  });
};

// DETACHING MEDIA
const detachRemoteAudio = () => {
  const audioElement = audioElementRef.current;
  audioElement.srcObject = null;
};

// SIP CONNECTION
const setupSIPClient = () => {
  const extension = sessionStorage.getItem('extension');
  const extensionPass = sessionStorage.getItem('extensionpass');
  const serverPort = sessionStorage.getItem('serverport');
  const serverIP = sessionStorage.getItem('serverip');
  userAgentRef.current = new UA({
    uri: `sip:${extension}@${serverIP}`,
    transportOptions: {
      wsServers: [`wss://${serverIP}:${serverPort}/ws`],
    },
    authorizationUser: `${extension}`,
    password: `${extensionPass}`
  });
userAgentRef.current.start();
userAgentRef.current.on('disconnected', (error) => {
  console.log('WebSocket Closed:',error);
});
}

// flag incoming
let hasIncomingCall = false;

// HANDLING INCOMING CALLS 
const handleIncomingCall = () => {
        const extension = sessionStorage.getItem('extension');
        userAgentRef.current.on('invite', (session) => {

          // incomingSessionRef.current = session;

          // if(incomingSessionRef.current){
          //   session.reject();
          //   stopIncomingTone();
          //   return;
          // }

          if (!hasIncomingCall) {
            incomingSessionRef.current = session;
            hasIncomingCall = true;
          } else {
            session.reject();
            stopIncomingTone();
          }

          if(isMakingCall.current){
            session.reject();
            stopIncomingTone();
            return;
          }
        // auto answer
        if(AutoAnswerRef.current){
          acceptCall();
          return;
          }
          //Take Breaks
          if (isBreakRef.current) {
          session.reject();
          stopIncomingTone();
          return;
          }

 // REJECT CALL IF SUPERVISOR ENABLES
          if(sessionStorage.getItem("user_level") === "supervisor"){
            if (isDisableRef.current) {
              session.reject();
              stopIncomingTone();
              return;
              }
          }

          currentCallRef.current=session;
        if (!isOnCall.current) {
        if(!currentOutgoingCallRef.current){
          callID.current=session.request.getHeader('Call-ID');
          isOnCall.current = true;
          // HANDLE INCOMING CALL
          const fromNumber = session.remoteIdentity.uri.user;
          // INCOMING CALL BROWSER NOTIFICATION
          if (Notification.permission === "granted") {
          new Notification(`Incoming Call ( ${fromNumber} )`);
          } else if (Notification.permission !== "denied") {
          Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
          new Notification(`Incoming Call ( ${fromNumber} )`);
          }
          });
          }
          //HANDLE INCOMING URL
          setRemoteUri(fromNumber);
          setDispositionNumber(fromNumber);
          setSelectedTab(0);
          setDialer(false);
          setShowCall(true);
          setAcceptedCall(false);
          handleCallerName(fromNumber);
          playIncomingTone();
          setMakingCall(false);
        }
            // handle incoming call accepted
            currentCallRef.current.on('accepted', () => {
              isOnCall.current = true;
              const fromNumber = incomingSessionRef.current.remoteIdentity.uri.user;
              attachRemoteAudio(incomingSessionRef.current||currentCallRef.current);
              setShowCall(false);
              setAcceptedCall(true);
              stopIncomingTone();
              startTime();
              handleConfirmed();
              setCallType('incoming');
              handleAddCalllogs(callID.current,fromNumber,extension,disposition,'Picked','incoming');
              setIsDispositionHangupButtonVisible('');
              });

          //TIMER
          let myformattedTime;
          let intervalId;
  
          const handleConfirmed = () => {
              const startTime = new Date();
              intervalId =  setInterval(() => {
              const currentTime = new Date();
              const elapsedTime = currentTime - startTime;
              const seconds = Math.floor((elapsedTime / 1000) % 60);
              const minutes = Math.floor((elapsedTime / 1000 / 60) % 60);
              const hours = Math.floor((elapsedTime / 1000 / 60 / 60) % 24);
              const formatTime = (hours, minutes, seconds) => {
              const paddedHours = hours.toString().padStart(2, '0');
              const paddedMinutes = minutes.toString().padStart(2, '0');
              const paddedSeconds = seconds.toString().padStart(2, '0');
              return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
              };
              myformattedTime = formatTime(hours, minutes, seconds);
              }, 1000);
          };
    
          // Handle call termination (remote hang-up)
          currentCallRef.current.on('terminated', () => {
            isMakingCall.current = false;
            currentCallRef.current = null;
            isOnCall.current = false;
            callID.current = null;
            incomingSessionRef.current = null;
            hasIncomingCall=false;
            if(currentCallRef.current){
                  isOnCall.current = false;
                  const fromNumber = session.remoteIdentity.uri.user;
                  if(myformattedTime){
                  handleAddCalllogs(callID.current,fromNumber,extension,disposition,myformattedTime,'incoming');
                  }else{
                  handleAddCalllogsMissed(callID.current,fromNumber,extension,disposition,'Missed','incoming');
                  }
                  remoteAudioStreamRef.current = new MediaStream();
                  currentCallRef.current = null;
                  callID.current = null;
                  completeHangup();
            }
            if(!isOnCall.current){
              completeHangup();
            }
            setIsDispositionHangupButtonVisible('none');
          });
        }else{
          const fromNumber = incomingSessionRef.current.remoteIdentity.uri.user;
          handleAddCalllogs(fromNumber,extension,disposition,'Tried Calling','incoming');
        }

        if (isOnCall.current) {           
          // Reject the incoming call if there's already an ongoing call
          // session.reject();
          // return;
          const fromNumber = incomingSessionRef.current.remoteIdentity.uri.user;
          handleAddCalllogs(fromNumber,extension,disposition,'Tried Calling','incoming');
          }
      });

    return () => {
    if (userAgentRef.current) {
        userAgentRef.current.stop();
        currentCallRef.current = null;
        isOnCall.current = false;
        currentOutgoingCallRef.current = null;
        hasIncomingCall = false;
        hasOugoingCall = false;
        isMakingCall.current = false;
        setIsDispositionHangupButtonVisible('none');
    }
    };
};



// USER REGISTRATION
const handleUserRegister = () => {
    try{        if (userAgent) {
        userAgent.register(); // Re-register the user agent
      }

      userAgentRef.current.on('transportError', (error) => {
        setRegistered(false);
        setConnectedBackground('#FF160C');
        setIsConnectedTip('Disconnected');
        // You can implement custom handling here, such as logging the error and taking appropriate actions.
      })

      userAgentRef.current.on('registered', () => {
          setRegistered(true);
          setConnectedBackground('#39FF14');
          setIsConnectedTip('Connected');
      });

      userAgentRef.current.on('registrationFailed', (response) => {
        setRegistered(false);
        setConnectedBackground('#FF160C');
        setIsConnectedTip('Disconnected');
      });
    
    }
      catch(error){}

}

// AUTO REFRESHING
useEffect(() => {
    const randInterval = setInterval(() => {
        try{handleUserRegister();}catch(error){}
    }, 10000);
    return () => {
      clearInterval(randInterval);
    };
  }, []);


useEffect(() => {
    try {setupSIPClient(); handleIncomingCall(); handleUserRegister();}catch{}
    setRemoteUri('');
  }, []);


// AUTO REFRESHING
  useEffect(() => {
    const delay = 3000; // 3 seconds in milliseconds
    // Use setTimeout to delay execution of the code
    const timer = setTimeout(() => {
      if (!sessionStorage.getItem("get_register")) {
        if (!registered) {
          window.location.reload();
          sessionStorage.setItem(
            "get_register",
            `${new Date().getTime()}${Math.floor(Math.random() * 1000)}`
          );
        }
      }
    }, delay);
    return () => clearTimeout(timer);
  }, []);

// Flag Outgoing Call
let hasOugoingCall = false;

// MAKING OUTGOING CALL 
const makeCall = () => {
  const extension = sessionStorage.getItem('extension');
  const serverIP = sessionStorage.getItem('serverip');
  stopIncomingTone();
  if(display){
    isMakingCall.current = true;
    setDialer(false);
    setMakingCall(true);
    stopIncomingTone();
let dialednumber;  
if(display>4){
  const DialNumber = display.replace(/\s/g, '');
  const cleanedNumber = DialNumber.replace(/\D/g, '');
  if (/^0\d{9}$/.test(cleanedNumber)) {
    const internationalNumber = `254${cleanedNumber.substring(1)}`;
    dialednumber = internationalNumber;
  } else if (/^254\d{9}$/.test(cleanedNumber)) {
    dialednumber = cleanedNumber;
  } else {
    dialednumber = DialNumber;
  }
}else{
  const DialNumber = display.replace(/\s/g, '');
  dialednumber = DialNumber;
}
  handleCallerName(dialednumber);
  const targetURI = `sip:${dialednumber}@${serverIP}`;
  const session = userAgentRef.current.invite(targetURI, {
    sessionDescriptionHandlerOptions: {
      constraints: { audio: true, video: false },
      mediaConstraints: { audio: true, video: false },
    },
  });
  session.on('invite', () => {
  callID.current=session.request.getHeader('Call-ID');
  });
      // TIMER
      let formattedTime;
      let intervalId;
  
      const handleConfirmed = () => {
        const startTime = new Date();
        intervalId =  setInterval(() => {
          const currentTime = new Date();
          const elapsedTime = currentTime - startTime;
          const seconds = Math.floor((elapsedTime / 1000) % 60);
          const minutes = Math.floor((elapsedTime / 1000 / 60) % 60);
          const hours = Math.floor((elapsedTime / 1000 / 60 / 60) % 24);
          const formatTime = (hours, minutes, seconds) => {
            const paddedHours = hours.toString().padStart(2, '0');
            const paddedMinutes = minutes.toString().padStart(2, '0');
            const paddedSeconds = seconds.toString().padStart(2, '0');
            return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
          };
          formattedTime = formatTime(hours, minutes, seconds);
        }, 1000);
      };


  // ASSIGN OUTGOING REF A SESSION
  currentOutgoingCallRef.current = session;

  // OUTGOING CALL IN PROGRESS
  currentOutgoingCallRef.current.on('progress', () => {
    setShowCall(false);
    playOutgoingTone();
    hasOugoingCall = true;
  });

  // OUTGOING CALL IN ACCEPTED
  currentOutgoingCallRef.current.on('accepted', () => {
    isOnCall.current = true;
    currentOutgoingCallRef.current = session;
    hasOugoingCall = false;
    attachRemoteAudio(session);
    setMakingCall(false);
    setAcceptedCall(true);
    startTime();
    handleConfirmed();
    setCallType('outgoing');
    stopOutgoingTone();
    setRemoteUri(display);
    setDispositionNumber(display);
    setIsDispositionHangupButtonVisible('');
  });


  // OUTGOING CALL IN FAILED
  currentOutgoingCallRef.current.on('failed', () => {
    hasIncomingCall=false;
    detachRemoteAudio();
    currentOutgoingCallRef.current = null;
    hasOugoingCall = false;
    setIsDispositionHangupButtonVisible('none');
    completeHangup();
  });


  // HANDLE OUTGOING TERMINATION (TERMINATION)
  currentOutgoingCallRef.current.on('terminated', () => {
    if(!hasOugoingCall){
      completeHangup();
    }
      if(formattedTime){
        handleAddCalllogs(callID.current,extension,display,disposition,formattedTime,'outgoing');
      }else{
        handleAddCalllogs(callID.current,extension,display,disposition,'Not Found','outgoing');
      }
    isMakingCall.current=false;
    if(currentOutgoingCallRef.current){
      completeHangup();
      clearInterval(intervalId);
    remoteAudioStreamRef.current = new MediaStream();
    currentOutgoingCallRef.current = null;
    callID.current = null;
    isOnCall.current = false;
    refreshDashoardAfterCall();
    if(callAccepted.current){
      completeHangup(); }
 }
 setIsDispositionHangupButtonVisible('none');
  });  
}
};

// HANDLE CANCLING CALL 
const cancelCall = () => {
  completeHangup();
if (currentOutgoingCallRef.current) {
        completeHangup();
        currentOutgoingCallRef.current.cancel();
        isOnCall.current = false;
    }
};

// HANDLE ACCEPT CALL
const acceptCall = () => {
        callAccepted.current = true;
        stopIncomingTone();
    if (currentCallRef.current) {
        currentCallRef.current.accept({
          sessionDescriptionHandlerOptions: {
            constraints: { audio: true, video: false },
            mediaConstraints: { audio: true, video: false },
          }
        });
        // isOnCall.current = true;
        setIncomingStatus('CONNECTING...');
        stopIncomingTone();
        if (isOnCall.current) {
          setTimeout(() => {
            if(currentCallRef.current){
              setShowCall(false);
              setAcceptedCall(true);
              stopIncomingTone();
              setDialer(false);
            }else{
              setDialer(true);
              setAcceptedCall(false);
            }
          }, 4000); // 4000 milliseconds (4 seconds)
        }else{
          setDialer(true);
          setAcceptedCall(false);
        }}
}

// REJECT CALL
const rejectCall = () => {
          completeHangup();
  if (currentCallRef.current) {
        try {
          completeHangup();
          currentCallRef.current.reject();
        } catch (error) {
          console.error('Error rejecting call:', error);
        }
      }
}

// HANGUP CALL 
const hangupCall = () => {
  completeHangup();
  const callSession = currentOutgoingCallRef.current || currentCallRef.current;
  isMakingCall.current = false;
    if (callSession) {
        try {
          completeHangup();
          callSession.terminate();
          isOnCall.current = false;
        } catch (error) {}
      }
}


// CLEAR DIALER SCREEN
const handleClearClick = () => {
  setDisplay('');
};

// HANDLE MUTING
const handleMicToggle = () => {
  const callSession = currentOutgoingCallRef.current || currentCallRef.current;
    if (callSession) {
        const peerConnection = callSession.sessionDescriptionHandler.peerConnection;
        const localAudioTrack = peerConnection.getSenders().find((sender) => {
          return sender.track && sender.track.kind === 'audio';
        });
        if (localAudioTrack) {
          localAudioTrack.track.enabled = !localAudioTrack.track.enabled;
          setIsMuteOn(!localAudioTrack.track.enabled);
        }
      }
}

// HANDLE HOLD
const handleHoldToggle = () => {
  const callSession = currentOutgoingCallRef.current || currentCallRef.current;
    if (callSession) {
          const direction = callSession.sessionDescriptionHandler.getDirection();
          if (direction === 'sendrecv') {
            callSession.hold();
            setIsHoldOn(true);
          } else {
            callSession.unhold();
            setIsHoldOn(false);
          }
      }
};

// HANDLE CALL TRANSFER
const handleTransferSubmit = (event) => {
    event.preventDefault();
    const serverIP = sessionStorage.getItem('serverip');
    const transferData = new FormData(event.target);
    const transferextn = transferData.get('transfer_extn');
    const callSession = currentOutgoingCallRef.current || currentCallRef.current;
    if (callSession) {
        try {
          const targetURI = `sip:${transferextn}@${serverIP}`;
          const transferOptions = {
            extraHeaders: ['Reason: SIP ;cause=302 ;text="Call Forwarded"'],
            sessionDescriptionHandlerOptions: {
              constraints: { audio: true, video: false },
              mediaConstraints: { audio: true, video: false },
            },
          };
          const transfer = callSession.refer(targetURI, transferOptions);
          if(transfer){
            callSession = null;
            setTransferOpen(false);
            hangupCall();
          }
        } catch (error) {
          console.error('Error forwarding call:', error);
        }
      }
}

// HANDLE CALL CONFERENCING
const handleConferenceChange = (event) => {
  //
  setConference(event.target.value);
}


const handleConferenceSubmit = (event) => {
  event.preventDefault();
  //
  setConferenceOpen(false);
}


// COMPLETE HANGUP
const completeHangup = (event) => {
  const callSession = currentOutgoingCallRef.current || currentCallRef.current;
  hasIncomingCall = false;
  hasOugoingCall = false;
  isMakingCall.current = false;
  setCallStatus('Ended');
  setIsHoldOn(false);
  setIsMuteOn(false);
  setCallType('outgoing');
  setDisplay('');
  setRemoteUri('');
  setTransferOpen(false); 
  setDTMFOpen(false);
  setRinging('MAKING CALL');
  setMakingCall(false);
  setShowCall(false);
  setDialer(true);
  setAcceptedCall(false);
  playEndTone();
  stopOutgoingTone();
  resetTime();
  setIncomingStatus('INCOMING CALL');
  detachRemoteAudio();
  stopIncomingTone();
  refreshDashoardAfterCall();
  if (callSession) {
      try {
        callSession.terminate();
        isOnCall.current = false;
      } catch (error) {}
    }
}


useEffect(() => {
const handleDialerOp = (event) => {
    setBarWidth(event.detail)
};
window.addEventListener('Edit_Dialer_Drawer', handleDialerOp);
return () => {
    window.removeEventListener('Edit_Dialer_Drawer',handleDialerOp);
};
}, []);

// ADDING NUMBERS TO DIALER
const handleButtonClick = (num) => {
  setDisplay(display + num);
};

// DELETING NUMBER
const handleButtonDelete = () => {
  setDisplay(display.slice(0, -1));
};

// HANDLING DTMF BUTTON
const handleDTMFButtonClick = (num) => {
 
  if(currentOutgoingCallRef.current) {
    const options = {
      requestOptions: {
        body: {
          contentDisposition: "render",
          contentType: "application/dtmf-relay",
          content: `Signal=${num}\r\nDuration=1000`
        }
      }
    }
    currentOutgoingCallRef.current.info(options);
      //  currentOutgoingCallRef.current.info(num);
    } else if (currentCallRef.current){
      const options = {
        requestOptions: {
          body: {
            contentDisposition: "render",
            contentType: "application/dtmf-relay",
            content: `Signal=${num}\r\nDuration=1000`
          }
        }
      }
      currentCallRef.current.info(options);
      // currentCallRef.current.info(num);
    } 
    setDtmfNo(dtmfno + num);
  }
  
  // COPYING PHONE NUMBER
  const copyPhoneNumber = (phone_number) => {
    const copyNumber = phone_number;
    const event = new CustomEvent('copyPhoneNumber', { detail: copyNumber });
    window.dispatchEvent(event);
  };

   // COPYING PHONE NUMBER FROM CALL LOGS
  useEffect(() => {
    // Define the event listener function
    const handleCopyPhone = (event) => {
    const copiedPhone = event.detail;
    setDisplay(copiedPhone);
    setSelectedTab(0);
      // setMakingCall(true);
      // setDialer(false);
    };
  
    // Attach the event listener to the window object
    window.addEventListener('copyPhoneNumber', handleCopyPhone);
  
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('copyPhoneNumber', handleCopyPhone);
    };


  }, []);

// TAB CHANGING
  useEffect(() => {
    const handleTabChange = (event) => {
    const tabNumber = event.detail;
    setSelectedTab(tabNumber);
    if(tabNumber===2){
      setResultsUp();
    }
    if(tabNumber===1){
      setCallResultsUp();
    }
      // setMakingCall(true);
      // setDialer(false);
    };
  
    // Attach the event listener to the window object
    window.addEventListener('tabChange', handleTabChange);
  
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('tabChange', handleTabChange);
    };

    
  }, []);


  // SEARCHING CONTACT FROM DIALER
  const handleSearchContactChange = (event) => {
    const contactvalue = event.target.value;
    setSearchContactValue(contactvalue);
    // Perform search logic here and update searchResults state
  
    api.get('/dialercontants/', {
      headers,
      withCredentials: true,
    })
      .then(response => {
        const resultdata = response.data;
        const filteredResults = resultdata.filter(item =>
          item.name.toLowerCase().includes(contactvalue.toLowerCase()) || 
          item.phone_number.toLowerCase().includes(contactvalue.toLowerCase()) || 
          item.category.toLowerCase().includes(contactvalue.toLowerCase())
        );
      
        setSearchContactResults(filteredResults);
      })
      .catch(error => {
        console.error(error);
      });
  
  };

  // HANDLE CONTACT NAME ATTACHED TO PHONE NUMBER
  const setResultsUp = () => {
    api.get('/dialercontants/', {
      headers,
      withCredentials: true,
    })
      .then(response => {
        const resultdata = response.data;
        const filteredResults = resultdata.filter(item =>
          item.name);
        setSearchContactResults(filteredResults);
      })
      .catch(error => {
        console.error(error);
      }); }

      // HANDLING INPUTS
      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };
    
      // HANDLE ADDING CONTACTS
      const handleContactSubmit = (event) => {
        event.preventDefault();
        api.post('/addcontact/', formData,{ withCredentials: true})
          .then((response) => {
            setResultsUp();
            setContactOpen(false);
            
          })
          .catch((error) => {
            console.error(error);
          });
      };


// HANDLE CONTACT NAME ATTACHED TO PHONE NUMBER  
const setCallResultsUp = () => {
  api.get('/minicalls/', {
    headers,
    withCredentials: true,
  })
    .then(response => {
      const callresultdata = response.data;
      const filteredCallResults = callresultdata.filter(item =>
        item.call_from);
      setSearchCallResults(filteredCallResults);
    }).catch(error => {
      console.error(error);
    }); }


const handleSearchCallChange = (event) => {
  const callvalue = event.target.value;
  setSearchCallValue(callvalue);
  api.get('/minicalls/', {
    headers,
    withCredentials: true,
  })
    .then(response => {
      const callresultdata = response.data;
      const filteredCallResults = callresultdata.filter(item =>
        item.call_from.toLowerCase().includes(callvalue.toLowerCase()) || 
        item.call_to.toLowerCase().includes(callvalue.toLowerCase()) || 
        item.call_date.toLowerCase().includes(callvalue.toLowerCase())
      );
    
      setSearchCallResults(filteredCallResults);
    })
    .catch(error => {
      console.error(error);
    });

};

// playing hangup tone
const playEndTone = () => {
  endcallRingtoneRef.current.play()
  .catch(error => {
  });
};
// Stop incoming ringtone
const stopEndTone = () => {
  endcallRingtoneRef.current.pause();
  endcallRingtoneRef.current.currentTime = 0;
};

// Play Incoming Ringtone
const playIncomingTone = () => {
  incomingRingtoneRef.current.play()
  .catch(error => {});
  // Listen if there is any in incoming call.
  const CallEvents = new CustomEvent('startCallEvents');
  window.dispatchEvent(CallEvents);
};

useEffect(() => {
  const setCallIncoming = () => {
    setShowCall(true);
    setDialer(false);
    setAcceptedCall(false);
    setSelectedTab(0);
  }
  window.addEventListener('startCallEvents', setCallIncoming);
  // Clean up the event listener when the component unmounts
  // return () => {
  //   window.removeEventListener('startCallEvents', setCallIncoming);
  // };
}, []);



// Stop incoming ringtone
const stopIncomingTone = () => {
  incomingRingtoneRef.current.pause();
  incomingRingtoneRef.current.currentTime = 0;
};

// Play Outgoing Ringtone
const playOutgoingTone = () => {
  outgoingRingtoneRef.current.play()
  .catch(error => {
  });
};

// Stop incoming ringtone
const stopOutgoingTone = () => {
  outgoingRingtoneRef.current.pause();
  outgoingRingtoneRef.current.currentTime = 0;
};

// ADDING CALL LOGS
const handleAddCalllogs = (call_id,source,destination,disposition,time,type) => {
  const params = {
    source: source,
    destination: destination,
    disposition: disposition,
    duration: time,
    type: type,
    call_id: call_id,
  };

  api.post('/addcalllog/', params,{ withCredentials: true})
  .then((response) => {

  })
  .catch((error) => {
    console.error(error);
  });
  setCallerName('')
}

// ADDING MISSED CALL LOGS
const handleAddCalllogsMissed = (call_id,source,destination,disposition,time,type) => {
  setTimeout(() => {
  const params = {
    source: source,
    destination: destination,
    disposition: disposition,
    duration: time,
    type: type,
    call_id: call_id,
  };

  api.post('/addcalllogmissed/', params,{ withCredentials: true})
  .then((response) => {

  })
  .catch((error) => {
    console.error(error);
  });
  setCallerName('')
}, 4000);
}

// HANDLING CALL NAME ATTACHED TO INCOMING CONTACT
const handleCallerName = (contact) => {
  if (contact) {
    const pattern = /^(\+254|254|0)/;
    // Function to clean a single phone number
    const cleanPhoneNumber = (phoneNumber) => {
      return phoneNumber.replace(pattern, '');
    };
    const cleanedContact = cleanPhoneNumber(contact); // Clean the phone number
    const params = {
      contact: cleanedContact, // Use the cleaned phone number
    };
    api.post('/callername/', params, { withCredentials: true })
      .then((response) => {
        setCallerName(<div><b>{response.data.name}</b><br /></div>);
      })
      .catch((error) => {
        setCallerName('');
      });
  } else {
    setCallerName('');
  }
};


useEffect(() => {
  return () => {
    resetTimer();
  };
}, []);

// EXRA INFO 
const handleExtraChange = (event) => {
  setExtraChange(event.target.value);
}
const handleDispositionChange = (event) => {
  setDispositionChange(event.target.value);
}

const handleDispositionSubmit  = (event) => {
  event.preventDefault();
  const formData = new FormData(event.target);
  api.post('/adddisposition/', formData,{ withCredentials: true})
    .then((response) => {
      setDispositionOpen(false);
    }).catch((error) => {console.error(error);});
};

const handleTransferChange = (event) => {
  setTransferExtension(event.target.value);
}


const handleTakingBreakClose = (value) => {
  setIsTakeBreakEnabled(value);
  setIsTakeBreakChecked(value);
  setTakingBreakOpen(value);
  resetTime();
  setBreakBtn('');
  setBreakDrop('');
  isBreakRef.current = false;
  const params = {
    end_time: new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' }),
    break_id: sessionStorage.getItem("break"),
  };

  api.patch('/addbreak/', params,{ withCredentials: true})
    .then((response) => {
      setResultsUp();
      setContactOpen(false);
      setBreakBtn('');
      setBreakDrop('');
      setBreakType('');
      resetBreakTime();
      isBreakType.current = '';
      sessionStorage.removeItem("break");
      window.location.reload();
      try {setupSIPClient();}catch{};
    })
    .catch((error) => {
      console.error(error);
    });

}


const handleTakeBreakSubmit = (event) => {
  event.preventDefault();
  const formData = new FormData(event.target);
  const break_type = formData.get('break_type');
  const break_id = sessionStorage.getItem("break");
  const params = {
    break_type: break_type,
    start_time: new Date().toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' }),
    break_id: sessionStorage.getItem("break"),
  };

  api.patch('/addbreak/', params,{ withCredentials: true})
    .then((response) => {
      isBreakRef.current = true;
      // startBreakTime();
      setResultsUp();
      setContactOpen(false);
      setBreakType(break_type);
      isBreakType.current = break_type;
      setBreakBtn('none');
      setBreakDrop('none');
    })
    .catch((error) => {
      console.error(error);
      isBreakRef.current = false;
    });
  }

  useEffect(() => {
  const chatvalue = "";
  const params = {search : chatvalue}
  api.post(`/getusers/`,params,{
    headers,
    withCredentials: true,
  })
    .then(response => {
      setUserResults(response.data);
    })
    .catch(error => {
      console.error(error);
    });
}, []);


const refreshDashoardAfterCall = () => {
  const event = new CustomEvent('refreshDashoardAfterCall');
  window.dispatchEvent(event);
};

const dispositionHangup = () => {
  completeHangup();
  setIsDispositionHangupButtonVisible('none');
}


// CUSTOM STYLES
const modalStyle =  {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  borderRadius: '10px',
  boxShadow: 24,
  p: 3,
};

const modalStyleDTMF =  {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 310,
  borderRadius: '10px',
  boxShadow: 24,
  p: 3,
};


  const DialButton = styled(Button)(({ theme }) => ({
    borderRadius: "100% !important",
    width: '50px',
    height: '65px',
    margin: '9px',
    color: colors.primary[100],
    backgroundColor: `${colors.primary[600]} !important`,
    fontSize: '2rem',
    '&:hover': {
      backgroundColor: colors.primary[600],
    },
  }));

  const CallButton = styled(Button)(({ theme }) => ({
    borderRadius: "100% !important",
    width: '70px',
    height: '70px',
    margin: '9px',
    color: 'white',
    backgroundColor: '#347036',
    fontSize: '2rem',
    '&:hover': {
      backgroundColor: '#4b7f4b',
    },
  }));



const ShakingRingVolumeIcon = styled(RingVolumeIcon)`
animation: shake 0.9s linear infinite;
transform-origin: center;
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(20deg);
  }
  20% {
    transform: rotate(-20deg);
  }
  30% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(5deg);
  }
  60% {
    transform: rotate(-5deg);
  }
  70% {
    transform: rotate(2.5deg);
  }
  80% {
    transform: rotate(-2.5deg);
  }
  90% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
`;

  return (
    <Box sx={{ display: 'flex', }}>

      <CssBaseline />

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor:colors.primary[700],
            border:'none!important'
          },
        }}
        variant="permanent"
        anchor="right"
      >
        <Toolbar />
        <Tooltip title={isConnectedTip} arrow>
      <Box sx={{width:'100%', borderBottom:`3px solid ${isConnectedBackground}`}}></Box>
      </Tooltip>
        {selectedTab === 0 && <span>


{/* RECIEVING PHONE */}
<Grid container spacing={1} sx={{ display: showCall ? 'flex' : 'none', mb: 1 , p:4}}>

<Grid item xs={12} sx={{ display: 'flex', gridColumn: "span 12", textAlign: "center", justifyContent: "center"}}>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <Typography variant='h6' sx={{marginTop:'20px'}}>{incomingStatus}</Typography>
    <span>  <IconButton sx={{color:'inherit',marginTop:'20px'}}>
    <ShakingRingVolumeIcon sx={{ fontSize: "60px" }} />
  </IconButton></span>
    <span>  
  <Typography sx={{fontSize: "23PX;"}}>
    {CallerName}
    {remoteUri}
  </Typography>
  </span>
  </div>
</Grid>


<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 ,fontSize: 14}}>
<DialButton sx={{ fontSize: 20, backgroundColor: "green !important"}} onClick={acceptCall}><IconButton sx={{color:'white'}}>
         <LocalPhoneIcon />
        </IconButton></DialButton>
<DialButton sx={{ fontSize: 20, backgroundColor: "red !important"}} onClick={rejectCall}><IconButton sx={{color:'white'}}>
          <PhoneDisabledIcon />
        </IconButton></DialButton>
</Grid>

</Grid>
{/* RECIEVING PHONE */}



{/* MAKING PHONE CALL */}

<Grid container spacing={1} sx={{ display: showMakingCall ? 'flex' : 'none', mb: 1 , p:4}}>

<Grid item xs={12} sx={{ display: 'flex', gridColumn: "span 12", textAlign: "center", justifyContent: "center"}}>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
<Typography variant='h6' sx={{marginTop:'20px'}}>{isRinging}</Typography>
    <span>  <IconButton sx={{color:'inherit'}}>
    <PermIdentityIcon sx={{ fontSize: "60px" }} />
  </IconButton></span>
    <span>  
  <Typography sx={{fontSize: "23PX;"}}>
    {CallerName}
    {display}
  </Typography></span>
  </div>
</Grid>


<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 ,fontSize: 14}}>

<DialButton sx={{ fontSize: 20, backgroundColor: "red !important",
              color: 'white'}} onClick={cancelCall}><IconButton sx={{color: 'inherit'}}>
          <LocalPhoneIcon />
        </IconButton></DialButton>
</Grid>

</Grid>
{/* MAKING PHONE CALL */}




{/* Phone RECIEVED */}

<Modal
        open={dtmfopen}
        onClose={() => {setDTMFOpen(false); setDtmfNo('');}}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={modalStyleDTMF}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">DTMF</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => {setDTMFOpen(false); setDtmfNo('');}} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          

            <Box sx={{paddingBottom:'10px'}}>
  <TextField
          label="DTMF"
          placeholder="DTMF"
          variant="outlined"
          fullWidth
          name="number"
          required="true"
          sx={{marginTop:"10px"}}
          value={dtmfno}
          disabled

        />
  </Box>

<Box display='flex' sx={{ alignItems: 'center',alignContent: 'center', justifyContent: 'center',}}>

<Box sx={{ alignItems: 'center',alignContent: 'center', justifyContent: 'center',}}>
<div>

<Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
color: colors.primary[100],height:'65px',borderRadius:'200px!important',margin: '9px',}} onClick={() => handleDTMFButtonClick('1')}>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
<span style={{ fontSize: 25, marginTop:"-26px" }}>1</span>
<span style={{ fontSize: 10, marginTop:"-14px" }}></span>
</div>
</Button>

<Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
color: colors.primary[100],height:'65px',borderRadius:'200px!important',margin: '9px',}} onClick={() => handleDTMFButtonClick('2')}> 
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
<span style={{ fontSize: 25, marginTop:"-6px" }}>2</span>
<span style={{ fontSize: 12, marginTop:"-15px" }}>ABC</span>
</div>
</Button>
<Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
color: colors.primary[100],height:'65px',borderRadius:'200px!important',margin: '9px',}} onClick={() => handleDTMFButtonClick('3')}>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
<span style={{ fontSize: 25, marginTop:"-6px" }}>3</span>
<span style={{ fontSize: 12, marginTop:"-15px" }}>DEF</span>
</div>
</Button>
</div>
<div>
<Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
color: colors.primary[100],height:'65px',borderRadius:'200px!important',margin: '9px',}} onClick={() => handleDTMFButtonClick('4')}>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
<span style={{ fontSize: 25, marginTop:"-6px" }}>4</span>
<span style={{ fontSize: 12, marginTop:"-15px" }}>GHI</span>
</div>
</Button>
<Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
color: colors.primary[100],height:'65px',borderRadius:'200px!important',margin: '9px',}} onClick={() => handleDTMFButtonClick('5')}>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
<span style={{ fontSize: 25, marginTop:"-6px" }}>5</span>
<span style={{ fontSize: 12, marginTop:"-15px" }}>JKL</span>
</div>
</Button>
<Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
color: colors.primary[100],height:'65px',borderRadius:'200px!important',margin: '9px',}} onClick={() => handleDTMFButtonClick('6')}>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
<span style={{ fontSize: 25, marginTop:"-6px" }}>6</span>
<span style={{ fontSize: 12, marginTop:"-15px" }}>MNO</span>
</div>
</Button>
</div>
<div>
<Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
color: colors.primary[100],height:'65px',borderRadius:'200px!important',margin: '9px',}} onClick={() => handleDTMFButtonClick('7')}>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
<span style={{ fontSize: 25, marginTop:"-6px" }}>7</span>
<span style={{ fontSize: 12, marginTop:"-15px" }}>PQRS</span>
</div>
</Button>
<Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
color: colors.primary[100],height:'65px',borderRadius:'200px!important',margin: '9px',}} onClick={() => handleDTMFButtonClick('8')}>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
<span style={{ fontSize: 25, marginTop:"-6px" }}>8</span>
<span style={{ fontSize: 12, marginTop:"-15px" }}>TUV</span>
</div>
</Button>
<Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
color: colors.primary[100],height:'65px',borderRadius:'200px!important',margin: '9px',}} onClick={() => handleDTMFButtonClick('9')}>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
<span style={{ fontSize: 25, marginTop:"-6px" }}>9</span>
<span style={{ fontSize: 12, marginTop:"-15px" }}>WXYZ</span>
</div>
</Button>
</div>
<div>
<Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
color: colors.primary[100],height:'65px',borderRadius:'200px!important',margin: '9px',}} onClick={() => handleDTMFButtonClick('*')}>      
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
<span style={{ fontSize: 35, marginTop:"14px" }}>*</span>
</div></Button>
<Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
color: colors.primary[100],height:'65px',borderRadius:'200px!important',margin: '9px',}} onClick={() => handleDTMFButtonClick('0')}>0</Button>
<Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
color: colors.primary[100],height:'65px',borderRadius:'200px!important',margin: '9px',}} onClick={() => handleDTMFButtonClick('#')}>#</Button>
</div>
<div>

</div>
</Box>
</Box>


        </div>
        </Box>
      </Modal>





<Grid container spacing={1} sx={{ display: showacceptedCall ? 'flex' : 'none', mb: 1 , p:4, }}>

<Grid item xs={12} sx={{ display: 'flex', gridColumn: "span 12", textAlign: "center", justifyContent: "center"}}>
<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <span>  
    <span>  <IconButton  sx={{color:'inherit'}}>
    <PermIdentityIcon sx={{ fontSize: "60px" }} />
  </IconButton></span>
  <Typography sx={{fontSize: "23PX;"}}>
    {CallerName}
    {remoteUri}
  </Typography>
  <Typography variant='h6'>{timer}</Typography>
  </span>
  </div>
</Grid>


<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 ,fontSize: 14}}>

  

<Button sx={{ fontSize: 20,backgroundColor: `red !important`,
              color: colors.grey[100],height:'65px',borderRadius:'200px!important',margin: '9px',}}onClick={hangupCall}><IconButton sx={{color:'inherit'}}>
          <LocalPhoneIcon />
        </IconButton></Button>
</Grid>


    <Grid item xs={4}>
      <Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
              color: colors.grey[100],height:'65px',borderRadius:'200px!important',margin: '9px',}} onClick={() => { handleMicToggle();}}>
                <Tooltip title={isMuteOn ? "Mute On" : "Mute"} arrow>
                <IconButton>
                {isMuteOn ? <MicOffIcon style={{ color: "#c62828" }} /> : <MicIcon />}</IconButton>
                </Tooltip>
              </Button>
    </Grid>

    <Grid item xs={4}>
      <Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
              color: colors.grey[100],height:'65px',borderRadius:'200px!important',margin: '9px',}} onClick={() => { handleHoldToggle();}}>
                <Tooltip title={isHoldOn ? "Hold On" : "Hold"} arrow>
                <IconButton>
                {isHoldOn ? <PlayArrowIcon style={{ color: "#c62828" }} /> : <PauseIcon  sx={{color:'inherit'}}/>}</IconButton>
                </Tooltip>
              </Button>
    </Grid>

    <Grid item xs={4}>
      <Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
              color: colors.grey[100],height:'65px',borderRadius:'200px!important',margin: '9px',}} onClick={() => setTransferOpen(true)}>
                <Tooltip title={isTransferOn ? "Transfer On" : "Transfer"} arrow>
                <IconButton>
                {isTransferOn ? <RedoIcon style={{ color: "#c62828" }} /> : <RedoIcon />}</IconButton>
                </Tooltip>
              </Button>
    </Grid>

    <Grid item xs={4}>
      <Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
              color: colors.grey[100],height:'65px',borderRadius:'200px!important',margin: '9px',}} onClick={() => setDispositionOpen(true)}>
                <Tooltip title="add Disposition" arrow>
                <IconButton>
                <NoteAltIcon/>
                </IconButton>
                </Tooltip>
              </Button>
    </Grid>

    <Grid item xs={4}>
      <Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
              color: colors.grey[100],height:'65px',borderRadius:'200px!important',margin: '9px',}}>
                <Tooltip title="Conference Call" arrow>
                <IconButton>
                <PeopleOutlineIcon/>
                </IconButton>
                </Tooltip>
              </Button>
    </Grid>

    <Grid item xs={4}>
      <Button sx={{ fontSize: 20,backgroundColor: `${colors.primary[600]} !important`,
              color: colors.grey[100],height:'65px',borderRadius:'200px!important',margin: '9px',}} onClick={() => setDTMFOpen(true)}>
                <Tooltip title="send DTMF" arrow>
                <IconButton>
                <KeyboardIcon/>
                </IconButton>
                </Tooltip>
              </Button>
    </Grid>




    <Modal
        open={transferopen}
        onClose={() => setTransferOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={modalStyle}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Transfer Call</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setTransferOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          
          <form onSubmit={handleTransferSubmit}>
          <Grid container spacing={1}>

<Grid item xs={12}>
<TextField
        fullWidth
        select
        label="Transfer"
        name="transfer_extn"
        sx={{marginTop:"10px"}}
        value={transferExtension}
        onChange={handleTransferChange}
        required="true"
      >

<MenuItem disabled>Select User</MenuItem>
{UserResults.map((row, index) => (
                <MenuItem value={row.user_extension}>{row.user_name}-{row.user_extension}</MenuItem>
              ))}
      </TextField>
</Grid>

<Grid item xs={12}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: colors.blueAccent[600]}}>Transfer This Call To {transferExtension}</Button>
            </Grid>

            </Grid>
          </form>
        </div>
        </Box>
      </Modal>


      <Modal
        open={conferenceOpen}
        onClose={() => setConferenceOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={modalStyle}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Make Conference Call</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setConferenceOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          
          <form onSubmit={handleConferenceSubmit}>
          <Grid container spacing={1}>


<Grid item xs={12}>
<TextField
        fullWidth
        select
        label="Transfer"
        name="transfer_extn"
        sx={{marginTop:"10px"}}
        value={conferenceExtension}
        onChange={handleConferenceChange}
        required="true"
      >
        <MenuItem value="">Select Extension</MenuItem>
        <MenuItem value="3002">3002</MenuItem>
        <MenuItem value="199">199</MenuItem>
        <MenuItem value="198">198</MenuItem>
        <MenuItem value="197">197</MenuItem>
      </TextField>
</Grid>





<Grid item xs={12}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: colors.blueAccent[600]}}>Make Conference Call to {conferenceExtension}</Button>
            </Grid>

            </Grid>
          </form>
        </div>
        </Box>
      </Modal>





    <Modal
        open={dispositionopen}
        onClose={() => setDispositionOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={modalStyle}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add Disposition</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setDispositionOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          
          <form onSubmit={handleDispositionSubmit}>
          <Grid container spacing={1}>

          <Grid item xs={6}>
<TextField
          label="Number"
          placeholder="Number"
          variant="outlined"
          fullWidth
          name="number"
          required="true"
          sx={{marginTop:"10px"}}
          value={dispositionnumber}
          disabled

        />

<TextField
          label="Disposition Contact"
          placeholder="Disposition Contact"
          variant="outlined"
          fullWidth
          name="disposition_contact"
          required="true"
          sx={{display:'none'}}
          value={dispositionnumber}
        />

<TextField
          label="Disposition Owner"
          placeholder="Disposition Owner"
          variant="outlined"
          fullWidth
          name="disposition_owner"
          required="true"
          sx={{marginTop:"10px",display:'none'}}
          value={sessionStorage.getItem('extension')}
        />

<TextField
          label="Call Type"
          placeholder="Call Type"
          variant="outlined"
          fullWidth
          name="call_type"
          required="true"
          sx={{marginTop:"10px",display:'none'}}
          value={callType}
        />
</Grid>

<Grid item xs={6}>
<TextField
        fullWidth
        select
        label="Disposition"
        name="disposition"
        sx={{marginTop:"10px"}}
        value={dispositions}
        onChange={handleDispositionChange}
        required="true"
      >
        <MenuItem value="">Select a Disposition</MenuItem>
        <MenuItem value="Call Back">Call Back</MenuItem>
        <MenuItem value="Sorted">Sorted</MenuItem>
        <MenuItem value="Not Interested">Not Interested</MenuItem>
        <MenuItem value="Escalating">Escalating</MenuItem>
        <MenuItem value="Would Like To Know More">Would Like To Know More</MenuItem>
      </TextField>
</Grid>

<Grid item xs={6}>
        <TextField
          fullWidth
          select
          label="Category"
          name="category"
          onChange={handleInputCategoryChange}
          required
          sx={{ marginTop: '5px' }}
          value={selectedCategory}
        >
          <MenuItem disabled>Select Ticket Category</MenuItem>
          {TicketCatgoryDataStatic.map((row) => (
            <MenuItem key={row.category} value={row.category}>
              {row.category}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <TextField
          fullWidth
          select
          label="Sub Category"
          name="subcategory"
          onChange={handleInputCategoryChange}
          required
          sx={{ marginTop: '5px' }}
          value={selectedSubcategory}
        >
          <MenuItem disabled>Select Ticket Sub Category</MenuItem>
          {selectedCategory &&
            TicketCatgoryDataStatic.find((row) => row.category === selectedCategory).subcategories.map((subcat) => (
              <MenuItem key={subcat} value={subcat}>
                {subcat}
              </MenuItem>
            ))}
        </TextField>
      </Grid>

<Grid item xs={12}>
        <TextField
          multiline
          rows={2}
          label="Extra Info"
          placeholder="Extra Info"
          value={extrachange}
          onChange={handleExtraChange}
          variant="outlined"
          fullWidth
          name="disposition_info"
          required="true"
        />
        </Grid>

<Grid item xs={6}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',width:"100%", backgroundColor: colors.blueAccent[600]}}>Submit Disposition</Button>
</Grid>

<Grid item xs={6}>
    <Button type="button" variant="contained" sx={{ display: isDispositionHangupButtonVisible, padding:"10px",marginTop:"10px",color:'white',width:"100%",backgroundColor: '#c62828'}} onClick={() => dispositionHangup()} >Hang Up</Button> 
</Grid>

            </Grid>
          </form>
        </div>
        </Box>
      </Modal>
    </Grid>
    {/* Phone RECIEVED */}




   {/* DIALER                  */}
<Box p={1}></Box>
        <Box sx={{ display: showDialer ? '' : 'none'}}>
        <Box display='flex' sx={{ alignItems: 'center',alignContent: 'center', justifyContent: 'space-between',}}>
        <Box><IconButton onClick={() => handleButtonClick('+')} sx={{marginLeft:'10px',color:'inherit',}}><AddIcon sx={{ marginTop:'2px'}}/></IconButton></Box>
        <Box>
        <TextField
          value={display}
          onChange={(event) => setDisplay(event.target.value)}
          placeholder="Enter Phone Number"
          sx={{
            width:"100%",
            color:'inherit !important',
            // borderBottom: '1px solid gray',
            fontSize: '30px !important',
            '& input': {
              fontSize: '20px',
            },
            '&:hover': {
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                display: 'none',
              },
              '&:hover fieldset': {
                display: 'none',
              },
              '&.Mui-focused fieldset': {
                display: 'none',
              },
            },
          }}
        /></Box>
        <Box><IconButton onClick={handleButtonDelete}  sx={{marginRight:'10px',color:'inherit',}}><BackspaceIcon sx={{marginTop:'2px'}}/></IconButton></Box></Box>
<Divider sx={{marginTop:'5px'}}/>

<Box display='flex' sx={{ alignItems: 'center',alignContent: 'center', justifyContent: 'center',}}p={1}>

        <Box sx={{ alignItems: 'center',alignContent: 'center', justifyContent: 'center',}}>
      <div>
        <DialButton number={1} onClick={() => handleButtonClick('1')}>        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <span style={{ fontSize: 25, marginTop:"-26px" }}>1</span>
    <span style={{ fontSize: 10, marginTop:"-14px" }}></span>
  </div></DialButton>
        <DialButton number={2} onClick={() => handleButtonClick('2')}>  
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <span style={{ fontSize: 25, marginTop:"-6px" }}>2</span>
    <span style={{ fontSize: 12, marginTop:"-15px" }}>ABC</span>
  </div>
  </DialButton>
        <DialButton number={3} onClick={() => handleButtonClick('3')}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <span style={{ fontSize: 25, marginTop:"-6px" }}>3</span>
    <span style={{ fontSize: 12, marginTop:"-15px" }}>DEF</span>
  </div>
        </DialButton>
      </div>
      <div>
        <DialButton number={4} onClick={() => handleButtonClick('4')}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <span style={{ fontSize: 25, marginTop:"-6px" }}>4</span>
    <span style={{ fontSize: 12, marginTop:"-15px" }}>GHI</span>
  </div>
        </DialButton>
        <DialButton number={5} onClick={() => handleButtonClick('5')}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <span style={{ fontSize: 25, marginTop:"-6px" }}>5</span>
    <span style={{ fontSize: 12, marginTop:"-15px" }}>JKL</span>
  </div>
        </DialButton>
        <DialButton number={6} onClick={() => handleButtonClick('6')}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <span style={{ fontSize: 25, marginTop:"-6px" }}>6</span>
    <span style={{ fontSize: 12, marginTop:"-15px" }}>MNO</span>
  </div>
        </DialButton>
      </div>
      <div>
        <DialButton number={7} onClick={() => handleButtonClick('7')}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <span style={{ fontSize: 25, marginTop:"-6px" }}>7</span>
    <span style={{ fontSize: 12, marginTop:"-15px" }}>PQRS</span>
  </div>
        </DialButton>
        <DialButton number={8} onClick={() => handleButtonClick('8')}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <span style={{ fontSize: 25, marginTop:"-6px" }}>8</span>
    <span style={{ fontSize: 12, marginTop:"-15px" }}>TUV</span>
  </div>
        </DialButton>
        <DialButton number={9} onClick={() => handleButtonClick('9')}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <span style={{ fontSize: 25, marginTop:"-6px" }}>9</span>
    <span style={{ fontSize: 12, marginTop:"-15px" }}>WXYZ</span>
  </div>
        </DialButton>
      </div>
      <div>
        <DialButton number="*" onClick={() => handleButtonClick('*')}>        
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <span style={{ fontSize: 35, marginTop:"14px" }}>*</span>
  </div></DialButton>
        <DialButton number={0} onClick={() => handleButtonClick('0')}>0</DialButton>
        <DialButton number="#" onClick={() => handleButtonClick('#')}>#</DialButton>
      </div>
      <div>
      <Box display='flex' sx={{ alignItems: 'center',alignContent: 'center', justifyContent: 'center',}}>
        <CallButton number={0} onClick={makeCall}><LocalPhoneIcon sx={{fontSize:"30px"}}/></CallButton>
        </Box>
      </div>
    </Box>
    </Box>
    </Box>

{/* DIALER */}
    </span>}


    {selectedTab === 1 && <span>


      <Box sx={{ height: "auto", overflowY: "auto"}}>

<Grid container spacing={2} sx={{p:4,marginTop: "-30px"}}>
<Grid item xs={12} sx={{textAlign:"center"}}>CALL HISTORY</Grid>
<Grid item xs={8}>
<TextField
          placeholder="Search..."
          value={searchCallValue || ""}
        onChange={handleSearchCallChange}
          sx={{
            width:"130%",
            marginTop: '-10px',
            border: 'none',
            fontSize: '30px !important',
            '& input': {
              fontSize: '17px',
            },
            '&:hover': {
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                display: 'none',
              },
              '&:hover fieldset': {
                display: 'none',
              },
              '&.Mui-focused fieldset': {
                display: 'none',
              },
            },
            marginLeft: '-10px'
          }}
        />
</Grid>
</Grid>

<Box  height="380px" overflowY="auto" marginTop="-30px">

<Box
position="sticky"
top="-5px"
display="flex"
justifyContent="space-between"
alignItems="center"
borderBottom={`1px solid ${colors.primary[600]}`}
p="12px"
backgroundColor={colors.primary[600]}
>
<Box m="1px">Source</Box>
<Box m="1px">Destination</Box>
<Box m="1px">Date</Box>
</Box>

{searchCallResults.map((calllogs, i) => ( 
<Box
display="flex"
justifyContent="space-between"
alignItems="center"
borderBottom={`1px solid ${colors.primary[600]}`}
p="12px"
onClick={ () => copyPhoneNumberLogs (calllogs.call_from, calllogs.call_to)}
sx={{
  cursor: 'pointer',
  color: calllogs.call_duration === 'Missed' ? '#ff5722' : ''
}}
>
<Box m="1px">{calllogs.call_from} </Box>
<Box m="1px">{calllogs.call_to}</Box>
<Box m="1px">{calllogs.call_time.slice(10, -13).replace('T', ' ')}</Box></Box>
 ))}
</Box>



</Box>

      
      </span>}
    


      {selectedTab === 2 && <span>
        <Box sx={{ height: "auto", overflowY: "auto"}}>

<Grid container spacing={2} sx={{p:4,marginTop: "-30px"}}>
<Grid item xs={12} sx={{textAlign:"center"}}>CONTACTS</Grid>
<Grid item xs={9}>
<TextField
          placeholder="Search..."
          value={searchContactValue || ""}
        onChange={handleSearchContactChange}
          sx={{
            width:"130%",
            marginTop: '-10px',
            border: 'none',
            fontSize: '30px !important',
            '& input': {
              fontSize: '17px',
            },
            '&:hover': {
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                display: 'none',
              },
              '&:hover fieldset': {
                display: 'none',
              },
              '&.Mui-focused fieldset': {
                display: 'none',
              },
            },
            marginLeft: '-10px'
          }}
        />
</Grid>
<Grid item xs={2}><Tooltip title="Add Contact" arrow><Button variant="contained" sx={{backgroundColor: colors.blueAccent[600],color:'white'}} onClick={() => setContactOpen(true)}><PersonAddAltIcon/></Button></Tooltip></Grid>
</Grid>
  


<Box  height="380px" overflowY="auto" marginTop="-30px">

<Box
position="sticky"
top="-5px"
display="flex"
justifyContent="space-between"
alignItems="center"
borderBottom={`1px solid ${colors.primary[600]}`}
p="12px"
backgroundColor={colors.primary[600]}
>
<Box m="1px">Name</Box>
<Box m="1px">Phone Number</Box>
</Box>

{searchContactResults.map((contactinfo, i) => ( 

<Box
display="flex"
justifyContent="space-between"
alignItems="center"
borderBottom={`1px solid ${colors.primary[600]}`}
p="12px"
>

<Box m="1px" sx={{cursor:"pointer"}} onClick={ () => copyPhoneNumber (contactinfo.phone_number)}>{contactinfo.name} </Box>
<Box m="1px" sx={{cursor:"pointer"}} onClick={ () => copyPhoneNumber (contactinfo.phone_number)}>{contactinfo.phone_number}</Box>
</Box>


 ))}
</Box>



</Box>

<Modal
        open={openContactModal}
        onClose={() => setContactOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={modalStyle}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add Contact</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setContactOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          <form onSubmit={handleContactSubmit}>
          <Grid container spacing={1}>
<Grid item xs={6}>
            <TextField
              label="Name"
              name="name"
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Phone Number"
              name="phone_number"
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Email"
              name="email"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={6}>
            <TextField
              label="Label"
              name="location"
              onChange={handleInputChange}
              fullWidth
              sx={{marginTop:"10px"}}
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={12}>
<TextField
        fullWidth
        select
        label="Category"
        name="category"
        sx={{marginTop:"10px"}}
        onChange={handleInputChange}
        required="true"
      >
        <MenuItem value="">Select a category</MenuItem>
        <MenuItem value="work">Work</MenuItem>
        <MenuItem value="personal">Personal</MenuItem>
      </TextField>
</Grid>

<Grid item xs={12}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:colors.blueAccent[600],color:'white'}}>Submit</Button>
            </Grid>

            </Grid>
          </form>
        </div>
        </Box>
        
      </Modal>



      </span>}



      {selectedTab === 3 && <span>
        <Box sx={{ height: "auto", overflowY: "auto"}}>
<Grid container spacing={2} sx={{p:4,marginTop: "-30px"}}>
<Grid item xs={12} sx={{textAlign:"center"}}>CALL SETTINGS</Grid>

{ sessionStorage.getItem("user_level") === "supervisor" && (
<Grid container sx={{marginTop: "30px"}}>
<Grid item xs={7} sx={{textAlign:"left"}}><Box sx={{fontSize:"15px", marginTop:"10px", marginLeft: "15px!important"}}>Enable Calls</Box></Grid>
<Grid item xs={5} sx={{textAlign:"right"}}>
<Switch
      checked={isCallEnabledChecked}
      onChange={handleDisableCalls}
      color={isCallEnabledChecked ? "success" : "error"}
    />
</Grid>
</Grid> )}


<Grid item xs={7} sx={{textAlign:"left"}}><Box sx={{fontSize:"15px", marginTop:"10px"}}>Take Break</Box></Grid>
<Grid item xs={5} sx={{textAlign:"right"}}>
<Switch
      checked={isTakeBreakChecked}
      onChange={handleTakingBreak}
      color="error"
    />
</Grid>


<Grid item xs={7} sx={{textAlign:"left"}}><Box sx={{fontSize:"15px", marginTop:"10px"}}>Auto Answer</Box></Grid>
<Grid item xs={5} sx={{textAlign:"right"}}>
<Switch
      checked={isAutoAnswerChecked}
      onChange={handleAutoAnswer}
      color="info"
    />
</Grid>



</Grid>
  
  </Box>
      </span>}

      {selectedTab === 4 && <span>
    <Box sx={{ height: "auto", overflowY: "auto"}}>
<Grid container spacing={2} sx={{p:4,marginTop: "-30px"}}>
{/* <Grid item xs={12} sx={{textAlign:"center"}}>POWER DIALER</Grid> */}

</Grid>

</Box>
    </span>}



      <audio ref={audioElementRef} autoPlay />
      </Drawer>



      <Modal
        open={openTakingBreakModal}
        // onClose={() => handleTakingBreakClose(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={modalStyle}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Break ( {breaktimer} )</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => handleTakingBreakClose(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          <form onSubmit={handleTakeBreakSubmit}>
          <Grid container spacing={1}>
<Grid item xs={12}>
<TextField
        fullWidth
        select
        label="Break Type"
        name="break_type"
        sx={{marginTop:"10px", display: BreakDrop}}
        onChange={handleInputChange}
        required="true"
      >
        <MenuItem disabled>Select</MenuItem>
        <MenuItem value="Lunch Break">Lunch Break</MenuItem>
        <MenuItem value="Short Break">Short Break</MenuItem>
        <MenuItem value="Scheduled Break">Scheduled Break</MenuItem>
        <MenuItem value="Team Meetings">Team Meetings</MenuItem>
        <MenuItem value="Emergency Break">Emergency Break</MenuItem>
        <MenuItem value="Quiet Time Break">Quiet Time Break</MenuItem>
      </TextField>

      {BreakType}
</Grid>



<Grid item xs={12}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",backgroundColor:colors.blueAccent[600],color:'white', display: BreakBtn}}>Submit</Button>
            </Grid>

            </Grid>
          </form>
        </div>
        </Box>
        
      </Modal>









      <Stack spacing={2} sx={{ width: '100%'}}>
  <Snackbar open={openServer} autoHideDuration={null} onClose={handleServerClose}  anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
  <Alert onClose={handleServerClose} severity="error" sx={{ width: '100%' }}>
No Connection To Server !!
  </Alert>
  </Snackbar>
  </Stack>

    </Box>
  );
}

export default DialBar;

    //   if(sessionStorage.getItem("user_level") === "supervisor"){
    //     if (!isDisableRef.current){
    //         if(myformattedTime){
    //           handleAddCalllogs(callID.current,fromNumber,extension,disposition,myformattedTime,'incoming');
    //         }else{
    //           handleAddCalllogsMissed(callID.current,fromNumber,extension,disposition,'Missed','incoming');
    //         }
    //       }
    //   }else{
    //     if(myformattedTime){
    //       handleAddCalllogs(callID.current,fromNumber,extension,disposition,myformattedTime,'incoming');
    //     }else{
    //       handleAddCalllogsMissed(callID.current,fromNumber,extension,disposition,'Missed','incoming');
    //     }
    //   }
    //   remoteAudioStreamRef.current = new MediaStream();
    //   currentCallRef.current = null;
    //   callID.current = null;
    //   completeHangup();